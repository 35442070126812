import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Routing from "./Routes";
import { useGuestLoginMutation } from "./service/Home";
import { STORAGE_KEYS, errorToast, getFromStorage } from "./helpers";
import { generateEncryptedKeyBody } from "./utils/crypto";
import { CommonBody } from "./types/General";
import { useLazyGetCmsQuery } from "./service/Cms";
import { useAppDispatch, useAppSelector } from "./hooks/store";
import { resetCms } from "./reducers/generalSlice";
import { getTempToken } from "./reducers/authSlice";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";

function App() {
  const [GetCms] = useLazyGetCmsQuery();
  const dispatch = useAppDispatch();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);

  const fetchCmsData = async () => {
    try {
      const response = await GetCms({}).unwrap();

      if (response?.statusCode === 200) {
        dispatch(resetCms({ cms: response?.data }));
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  useEffect(() => {
    if (token || tempToken) {
      fetchCmsData();
    }
  }, [tempToken]);
  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();
    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div style={{ color: "#820000" }}>{event?.data?.data?.title}</div>
              <div style={{ color: "#1d1d1d", paddingTop: "10px" }}>
                {event?.data?.data?.message}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);
  return <Routing />;
}

export default App;
