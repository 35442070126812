import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  useLazyGetProfileQuery,
  usePostSignUpMutation,
  usePostSocialLoginMutation,
} from "../../service/Auth";
import { useFormik } from "formik";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { log } from "console";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import app from "../../utils/firebaseKeys";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
  setEmailValue: Dispatch<SetStateAction<string>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setDialCode: Dispatch<SetStateAction<string>>;
  setSignUp: Dispatch<SetStateAction<boolean>>;
};
const provider = new GoogleAuthProvider();

const SignUp = ({
  closeModal,
  setPhone,
  setEmailValue,
  setPhoneNumber,
  setDialCode,
  setSignUp,
}: props) => {
  const navigate = useNavigate();
  const auth = getAuth(app);
  const [email, setEmail] = useState(false);
  const [postSignup, { isLoading }] = usePostSignUpMutation();
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+971");
  const [postSocialLogin] = usePostSocialLoginMutation();
  const [getProfile, GetProfileData] = useLazyGetProfileQuery();
  const dispatch = useAppDispatch();

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };
  console.log(email, "email");

  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      phone: email
        ? Yup.string().notRequired()
        : Yup.string()
            .required("This field is required")
            .min(8, "Please enter correct phone number")
            .max(14)
            .label("Phone number"),

      email: email
        ? Yup.string()
            .required("Email is required!")
            .matches(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              "Enter a valid email address!"
            )
        : Yup.string().notRequired(),
      password: email
        ? Yup.string()
            .required("Password is required!")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
              "Must contain 8 or more characters, one uppercase, one lowercase and one number."
            )
        : Yup.string().notRequired(),
      confirmPassword: email
        ? Yup.string()
            .oneOf([Yup.ref("password")], "Passwords must match.")
            .required("This field is required")
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

      let data = {
        ...(email
          ? { key: formik.values.email, password: formik.values.password }
          : {
              key: formik.values.phone,
              countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
            }),
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postSignup(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setEmailValue(formik.values.email || "");
          setPhoneNumber(formik.values.phone || "");
          setDialCode((phoneCode.includes("+") ? "" : "+") + phoneCode);
          closeModal();
          dispatch(
            toggleAuthModal({
              isAuthModalVisible: true,
              ModalType: "verification",
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log(user, "user");

      const deviceId = navigator.userAgent;

      const data = {
        email: user?.email,
        fullName: user?.displayName,
        image: user?.photoURL,
        socialId: user?.uid,
        socialType: "GOOGLE",
        deviceType: "WEB",
        deviceId: deviceId,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postSocialLogin(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(response?.data || "")
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
          closeModal();
          if (response?.data?.isProfileComplete) {
            navigate("/");
          } else {
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "profileSetup",
              })
            );
          }
          getUserDetails();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }

      // Now you can use the data object as needed, e.g., dispatch an action, call an API, etc.
    } catch (error) {
      console.error("Google Login Error:", error);
    }
  };
  const signInWithFacebook = async () => {
    const fbprovider = new FacebookAuthProvider();

    try {
      const result = await signInWithPopup(auth, fbprovider);
      const user = result.user;
      const deviceId = navigator.userAgent;
      const data = {
        email: user?.email,
        fullName: user?.displayName,
        image: user?.photoURL,
        socialId: user?.uid,
        socialType: "FACEBOOK",
        deviceType: "WEB",
        deviceId: deviceId,
      };
      console.log(data, "data");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postSocialLogin(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(response?.data || "")
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
          closeModal();
          if (response?.data?.isProfileComplete) {
            navigate("/");
          } else {
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "profileSetup",
              })
            );
          }
          getUserDetails();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    } catch (error) {
      console.error("Facebook Login Error:", error);
    }
  };

  return (
    <div>
      <div className="AuthWrap">
        <Loader isLoad={isLoading} />
        <div className={email ? "cross dbl" : "cross"}>
          {email ? (
            <KeyboardBackspaceIcon onClick={() => setEmail(false)} />
          ) : null}

          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>Welcome User</h2>
        <p>Please enter your details</p>
        <form onSubmit={formik.handleSubmit}>
          {email ? (
            <>
              <InputField
                placeholder="Email Id"
                name="email"
                id="email"
                value={formik.values.email}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.email && formik.errors.email}
              />
              <InputField
                placeholder="Password"
                password
                name="password"
                id="password"
                value={formik.values.password}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.password && formik.errors.password}
              />
              <InputField
                placeholder="Confirm Password"
                password
                name="confirmPassword"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </>
          ) : (
            <div className="form_control">
              <PhoneInput
                country={"in"}
                value={phoneCode + formik.values.phone}
                onChange={(phone: any, country: any) =>
                  handleChangePhone(phone, country)
                }
                onBlur={formik.handleBlur}
                placeholder="0 (000) 000-000"
                enableSearch={false}
                inputStyle={{ width: "100%", backgroundColor: "#EBEBEB" }}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <h6 className="err_msg">
                  {formik.touched.phone && formik.errors.phone}
                </h6>
              ) : (
                ""
              )}
              <div style={{ marginBottom: 10 }} />
            </div>
          )}

          <Button
            value="Next"
            //  onClick={handleContinue}
          />
        </form>
        <div className="continue">
          <h4
            onClick={() => {
              !email ? setPhone(false) : setPhone(true);
              setEmail(!email);
            }}
          >
            Sign up With {!email ? "Email" : "Phone Number"}
          </h4>
        </div>

        <div className="Or_div">
          <p>or</p>
        </div>
        <div className="social_icn">
          <figure
          // onClick={signInWithFacebook}
          >
            <img src="/static/images/facebook.svg" alt="Facebook" />
          </figure>
          <figure onClick={signInWithGoogle}>
            <img src="/static/images/google.svg" alt="Facebook" />
          </figure>
        </div>
        <div className="signUp">
          <h4>
            Already a member?{" "}
            <span
              onClick={() => {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "login",
                  })
                );
                setSignUp(false);
                setPhone(true);
              }}
            >
              Login
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
