import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { Button } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isString } from "../../utils/validations";

const ManagePayments = () => {
  const [open, setOpen] = useState<boolean>(false);
  const style = generateResponsiveStyle();

  const data = [
    {
      id: 1,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 2,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 3,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
    {
      id: 4,
      card_img: "/static/images/visa.png",
      card_number: "•••• •••• •••• 4432",
      description: "Personal - 06/23",
    },
  ];
  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required("This field is required")

        .min(3, "Card holder name must be at least 3 characters")
        .max(20),
      cardNumber: Yup.string().required("This field is required").min(16),
      expiryDate: Yup.string().required("This field is required"),
      cvv: Yup.string().required("This field is required").min(3),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setOpen(false);
      formik.resetForm();
    },
  });

  return (
    <div className="main">
      <h2>Manage Payments</h2>
      {/* <ul className="cards_sec">
        {data?.map((item) => {
          return (
            <li className="mn_crds">
              <div className="crd_dtls">
                <figure>
                  <img src={item?.card_img} alt="" />
                </figure>
                <div className="dtl_mn">
                  <h4>{item?.card_number || ""}</h4>
                  <p>{item?.description}</p>
                </div>
              </div>
              <figure className="delete_dv">
                <img src="/static/images/delete.png" alt="" />
              </figure>
            </li>
          );
        })}
      </ul> */}
      <div
        className="noData paymnt_nodata"
        style={{ padding: "120px 0 20px 0" }}
      >
        <figure>
          <img src="/static/images/no_card.png" alt="" />
        </figure>
        <h3>No Cards Found</h3>
      </div>
      <div className="add_crd" onClick={() => setOpen(true)}>
        <figure>
          <img src="/static/images/add_card.svg" alt="" />
        </figure>
        <div className="add_details">
          <h4>Add new card</h4>
          <p>Save and Pay via Cards.</p>
          <div className="paymnt_cards">
            <figure>
              <img src="/static/images/visa.svg" />
            </figure>
            <figure>
              <img src="/static/images/master-card.svg" />
            </figure>
            <figure>
              <img src="/static/images/discover.svg" />
            </figure>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>ADD NEW CARD</h2>
            <h5>Enter your card details.</h5>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <Typography variant="h6" className="custom_label2">
                    Card holder name
                  </Typography> */}
                  <TextField
                    fullWidth
                    value={formik.values.cardHolderName}
                    id="standard-basic"
                    placeholder="Card holder name"
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardHolderName"
                    inputProps={{ maxLength: 20 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.cardHolderName &&
                      formik.errors.cardHolderName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography variant="h6" className="custom_label2">
                    Card Number
                  </Typography> */}
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder="Card Number"
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <figure>
                            <img src="/static/images/visa.png" alt="" />
                          </figure>
                        </InputAdornment>
                      ),
                    }}
                    name="cardNumber"
                    inputProps={{ maxLength: 16 }}
                    value={formik.values.cardNumber}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.cardNumber && formik.errors.cardNumber
                    }
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <Grid item xs={5}>
                    {/* <Typography variant="h6" className="custom_label2">
                      Expiry Date
                    </Typography> */}
                    <TextField
                      className="paymnt_modl_txtfld"
                      variant="standard"
                      placeholder="MM/YYYY"
                      inputProps={{ maxLength: 7 }}
                      value={formik.values.expiryDate}
                      name="expiryDate"
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .replace(/\D/g, "") // Remove non-numeric characters
                          .replace(/(\d{2})(\d{0,4})/, "$1/$2"); // Format as MM/YYYY

                        formik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: formattedValue,
                          },
                        });
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Backspace") {
                          // Allow backspace for the month part
                          formik.handleChange({
                            target: {
                              name: "expiryDate",
                              value: formik.values.expiryDate.slice(0, -1),
                            },
                          });
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.expiryDate && formik.errors.expiryDate
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {/* <Typography variant="h6" className="custom_label2">
                      CVV
                    </Typography> */}
                    <TextField
                      fullWidth
                      id="standard-basic"
                      placeholder="CVV"
                      variant="standard"
                      className="paymnt_modl_txtfld"
                      name="cvv"
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <figure className="cvv_img">
                              <img src="/static/images/cvv.svg" alt="" />
                            </figure>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.cvv}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.cvv && formik.errors.cvv}
                    />
                  </Grid>
                </Box>
                <Grid item xs={12} className="sv_crd">
                  <FormControlLabel
                    control={<Checkbox />}
                    // checked={formik.values.remember}
                    name="remember"
                    // onChange={formik.handleChange}
                    label="Save this card for future payments?"
                  />
                </Grid>
                <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                  <Button value="Save" />
                </Box>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ManagePayments;
