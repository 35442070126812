export const API_URL = "https://apidwmdev.appgrowthcompany.com/api/v1/user/"; // development

export const END_POINTS = {
  signUp: "sendOtp",
  verifyotp: "verifyotp",
  login: "login",
  mediaUpload: "upload",
  profile: "profile",
  forgotPassword: "forgotpassword",
  resetPassword: "setPassword",
  getProfile: "getProfile",
  justSendOtp: "justSendOtp",
  justVerifyOtp: "justVerifyOtp",
  changepassword: "changepassword",
  logout: "logout",
  deleteAccount: "deleteAccount",
  socialLogin: "socialLogin",

  // home
  dashboard: "dashboard",
  recentProducts: "recentProducts",
  guestLogin: "guestLogin",
  globalSearch: "globalSearch",
  getProduct: "getProduct",
  getProfileById: "getProfileById",
  addWishlist: "addWishList",
  getWishlist: "getWishList",
  category: "category",
  getProductsByCategoryId: "getProductsByCategoryId",
  subCategory: "subCategory",
  follow: "follow",
  followList: "followList",
  makeAnOffer: "makeAnOffer",
  saveSearchHistory: "saveSearchHistory",
  searchHistory: "searchHistory",
  clearSearch: "clearSearch",
  featuredProducts: "featuredProducts",
  subscribeNewsletter: "subscribeNewsletter",

  //add product
  addProduct: "addProduct",
  getMyProduct: "getMyProduct",
  getProductById: "getProduct",
  updateProduct: "updateProduct",
  deleteProduct: "deleteProduct",

  //address
  addAddress: "addAddress",
  getMyAddress: "getMyAddress",
  updateAddress: "updateAddress",
  deleteAddress: "deleteAddress",
  selectAddress: "selectAddress",

  //cms
  cms: "cms",
  faq: "faq",
  review: "review",
  report: "reportUser",
  getBoosterPlans: "getBoosterPlans",
  buyBooster: "buyBooster",
  getMyBoosterPlan: "getMyBoosterPlan ",
  notification: "notification",

  //chat
  chatList: "chatList",
  clearChat: "clearChat",
  offerResponse: "offerResponse",
};
