import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import LogoHeader from "../../layout/logoHeader";
import { useLazyGetCategoriesQuery } from "../../service/Home";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { Category } from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";

const ChooseCategory = () => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      name: "Electronics & Computing",
      img: "/static/images/cate1.png",
    },
    {
      id: 2,
      name: "Construction Materials",
      img: "/static/images/cat_2.png",
    },
    {
      id: 3,
      name: "Machinery Material",
      img: "/static/images/cat3.png",
    },
    {
      id: 4,
      name: "Stationery & Office Supplies",
      img: "/static/images/cat4.png",
    },
    {
      id: 5,
      name: "Home Garden & Furniture",
      img: "/static/images/cat5.png",
    },
    {
      id: 6,
      name: "Food & Beverages",
      img: "/static/images/cat6.png",
    },
    {
      id: 7,
      name: "Health & Wellness",
      img: "/static/images/health.png",
    },
    {
      id: 8,
      name: "Sports",
      img: "/static/images/sports.png",
    },
    {
      id: 9,
      name: "Fashion & Beauty",
      img: "/static/images/fashion.png",
    },
    {
      id: 10,
      name: "Automative",
      img: "/static/images/automotive_cat.png",
    },
  ];
  const token = getFromStorage(STORAGE_KEYS.token);
  console.log("token: ", token);
  const tempToken = useAppSelector(getTempToken);

  const [getCategories, { isLoading }] = useLazyGetCategoriesQuery();

  const [categories, setCategories] = useState<Category[]>([]);

  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
    }
  }, [tempToken]);

  return (
    <>
      <LogoHeader />
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="container">
          <div className="choose">
            <h2>Choose a Category</h2>
            {categories?.length ? (
              <div className="options">
                <ul>
                  {categories?.length
                    ? categories?.map((item) => (
                        <li
                          key={item?._id}
                          onClick={() => navigate(`/add-product/${item?._id}`)}
                        >
                          <div className="cate_name">
                            {item?.image?.length ? (
                              <figure>
                                <img src={item?.image[0] || ""} alt="" />
                              </figure>
                            ) : null}

                            <h3>{item?.name}</h3>
                          </div>
                          <ArrowForwardIosIcon />
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseCategory;
