import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Box, Button, Modal } from "@mui/material";
import "./profile.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  EditProfile,
  ManageAddresses,
  ManagePayments,
  MyListing,
  TotalFollowers,
  TotalFollows,
} from "../../features";
import useAuth from "../../hooks/useAuth";
import { useDeleteAccountMutation } from "../../service/Auth";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  STORAGE_KEYS,
  errorToast,
  removeFromStorage,
  successToast,
} from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import { useLocation, useNavigate } from "react-router-dom";

const Profile = () => {
  const location = useLocation();
  const { state } = location;
  const user = useAuth();
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const dispatch = useAppDispatch();
  const [activeCase, setActiveCase] = useState<number>(0);
  const [newCls, setNewCls] = useState<boolean>(false);
  console.log(newCls, "newCls");

  const [openModal, setOpenModal] = useState(false);
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await deleteAccount({}).unwrap();
      if (response?.statusCode === 200) {
        successToast("Account deleted successfully");

        dispatch(
          setCredentials({
            token: null,
            user: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);

        closeModal();
        navigate("/");
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const data = [
    {
      id: 1,
      name: "My Listing",
      img: "/static/images/listing_icon.svg",
    },
    {
      id: 2,
      name: "Manage Payments",
      img: "/static/images/payment_icon.svg",
    },
    {
      id: 3,
      name: "Manage Addresses",
      img: "/static/images/location_icon.svg",
    },
  ];

  useEffect(() => {
    if (state === "listing") {
      setActiveCase(1);
    }
  }, []);
  const handleActiveCase = () => {
    switch (activeCase) {
      case 1:
        return <MyListing />;
      case 2:
        return <ManagePayments />;
      case 3:
        return <ManageAddresses />;
      case 4:
        return <TotalFollowers />;
      case 5:
        return <TotalFollows />;
      default:
        return <EditProfile />;
    }
  };

  const handleSwitch = (id: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setActiveCase(id);
    setNewCls(true);
  };

  useEffect(() => {
    handleActiveCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase]);

  useEffect(() => {
    if (state === "location") {
      setActiveCase(3);
    }
  }, []);

  return (
    <Layout>
      <div className="wrapCls" style={{ paddingTop: "0" }}>
        <div className="container">
          <div className="pMain">
            <div className="pLeft">
              <div
                className="userDetail"
                onClick={() => setActiveCase(0)}
                style={{ cursor: "pointer" }}
              >
                <figure>
                  <img
                    className={user?.image ? "img_style" : "img_style2"}
                    src={user?.image || "/static/images/empty_user.png"}
                    alt=""
                  />
                </figure>
                <div className="username">
                  <h3>{user?.fullName || ""}</h3>
                  <h4>{user?.email || ""}</h4>
                </div>
              </div>
              <div className="follow_dv">
                <div className="flw_sc" onClick={() => setActiveCase(4)}>
                  <p>Total followers</p>
                  <h6>{user?.followers || 0}</h6>
                </div>
                <div className="flw_sc" onClick={() => setActiveCase(5)}>
                  <p>Total following</p>
                  <h6>{user?.following || 0}</h6>
                </div>
              </div>
              <ul>
                {data?.map((item) => (
                  <li
                    key={item?.id}
                    className={activeCase === item?.id ? "active_tab" : ""}
                    onClick={() => {
                      setNewCls(true);
                      handleSwitch(item?.id);
                    }}
                  >
                    <figure>
                      <img src={item?.img} alt="" />
                    </figure>
                    <h3>{item?.name}</h3>
                  </li>
                ))}
              </ul>
              <Button
                style={{ width: "100%" }}
                onClick={() => setOpenModal(true)}
              >
                Delete Account
              </Button>
            </div>
            <div className={newCls ? "pRyt ryt_dsply" : "pRyt "}>
              {handleActiveCase()}
            </div>
          </div>
        </div>
      </div>
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={closeModal}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Are you sure you want to delete this account?
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteAccount();
                }}
              >
                Yes
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default Profile;
