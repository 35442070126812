import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Colors } from "../assets";

type InputTypes = {
  placeholder: string;
  clearBackground?: boolean;
  password?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  customIcon?: JSX.Element | null;
  value?: string;
  name?: string;

  id?: string;
  onChange?: (value: any) => void;
  onBlur?: any;
  helperText?: any;
  inputProps?: any;
};

const InputField = ({
  value,
  name,
  onBlur,
  id,
  onChange,
  helperText,
  placeholder,
  inputProps,
  clearBackground,
  password = false,
  multiline = false,
  disabled = false,
  rows = 1,
  customIcon = null,
}: InputTypes) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <TextField
        value={value}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        hiddenLabel
        variant="outlined"
        fullWidth
        className="text_field"
        name={name}
        multiline={multiline}
        disabled={disabled}
        inputProps={inputProps}
        rows={rows}
        placeholder={placeholder}
        type={password && !showPassword ? "password" : "text"}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment className="eye_btn" position="end">
              {password ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event: any) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ) : customIcon ? (
                customIcon
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default InputField;
