import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { useLazyGetFaqQuery } from "../../service/Cms";
import { Faq } from "../../types/General";
import { Loader } from "../../helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faqs = () => {
  const [faqData, setFaqData] = useState<Faq[]>([]);
  const [getFaq, { isLoading }] = useLazyGetFaqQuery();

  const fetchData = async () => {
    try {
      const response = await getFaq({}).unwrap();
      console.log(response, "responseCms");
      if (response?.statusCode === 200) {
        setFaqData(response?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const style = {
    borderRadius: "14px",
    boxShadow: "1px 1px 5px 4px #0000001A",
  };
  const border = {
    borderTop: "1px solid #DEDEDE",
  };
  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="common_policy ">
        <div className="container faq_dv">
          <h1> FAQ</h1>
          <ul className="faq_accoden">
            {faqData?.length ? (
              faqData?.map((item) => {
                return (
                  <li key={item?._id}>
                    <Accordion sx={style}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p style={{ color: "#820000" }}>
                          {item?.question || ""}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails sx={border}>
                        <Typography>{item?.answer || ""}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>No Faq listing found</p>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Faqs;
