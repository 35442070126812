import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components";
import { FeaturedProductsData } from "../../types/General";
import { useNavigate } from "react-router-dom";

type props = {
  featuredProducts: FeaturedProductsData[];
};

const FeaturedProducts = ({ featuredProducts }: props) => {
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = featuredProducts
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [featuredProducts]);

  return (
    <section className="prodcts_sc container ">
      <div className="prdct_hdngs pt">
        <h2>Featured Products</h2>
        {featuredProducts?.length > 5 ? (
          <p onClick={() => navigate(`/all-products/${"featured"}`)}>See All</p>
        ) : null}
      </div>
      <div className="crds_sec">
        {featuredProducts?.length ? (
          featuredProducts?.slice(0, 5)?.map((item) => {
            return (
              <ProductCard
                key={item?._id}
                product_id={item?._id}
                product_image={item?.images[0]}
                product_feature={item?.category?.name}
                product_name={item?.name}
                product_price={item?.price}
                booster_applied={item?.boosterApplied}
                location={item?.address}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
              />
            );
          })
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/empty.png" alt="" />
            </figure>
            <h3>No Product Found</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedProducts;
