import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
} from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import OTPInput from "react-otp-input";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  ModalType,
  country,
  email,
  phoneNo,
  toggleAuthModal,
} from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import CloseIcon from "@mui/icons-material/Close";
import {
  useLazyGetProfileQuery,
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";

type Props = {
  closeModal: () => void;
  phone: boolean;
  setPhone: Dispatch<SetStateAction<boolean>>;
  signUp: boolean;
  emailValue?: string;
  phoneNumber?: string;
  dialCode?: string;
};

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};

const Verification = ({
  closeModal,
  phone,
  signUp,
  emailValue,
  phoneNumber,
  dialCode,
  setPhone,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);
  const [postVerifyOtp, { isLoading }] = usePostVerifyOtpMutation();
  const [getProfile, GetProfileData] = useLazyGetProfileQuery();
  const [postForgotPassword, GetForgotPasswordData] =
    usePostForgotPasswordMutation();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleResendOtp = async () => {
    let data = {
      ...(!phone
        ? { key: emailValue }
        : {
            key: phoneNumber,
            countryCode: dialCode,
          }),
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await postForgotPassword(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");

      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);
      // setOpen(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const data = {
        ...(!phone
          ? { key: emailValue, code: otp }
          : {
              key: phoneNumber,
              countryCode: dialCode,
              code: otp,
            }),
        deviceType: "WEB",
        deviceToken: fcmtoken || "1234",
      };
      console.log(data, "data");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postVerifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (phone && !signUp) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );
            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || "",
              })
            );
            dispatch(
              temporaryToken({
                tempToken: null,
              })
            );
            closeModal();
            if (response?.data?.isProfileComplete) {
              navigate("/");
            } else {
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "profileSetup",
                })
              );
            }

            getUserDetails();
          } else if (signUp) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );

            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || "",
              })
            );
            closeModal();
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "profileSetup",
              })
            );
          } else {
            setToStorage(
              STORAGE_KEYS.tempToken,
              JSON.stringify(response?.data?.token)
            );
            dispatch(
              temporaryToken({
                tempToken: response?.data?.token || null,
              })
            );

            dispatch(
              toggleAuthModal({ isAuthModalVisible: true, ModalType: "reset" })
            );
          }
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <div>
      <div className="AuthWrap">
        <Loader isLoad={isLoading || GetForgotPasswordData?.isLoading} />
        <div className="cross">
          <CloseIcon
            onClick={() => {
              setPhone(true);
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              );
            }}
          />
        </div>
        <h2>Verification</h2>

        {phone ? (
          <p>
            We have just sent you a verification code via{" "}
            <span>{dialCode && phoneNumber ? dialCode + phoneNumber : ""}</span>
          </p>
        ) : (
          <p>
            We have sent an OTP code to your email{" "}
            <span>{emailValue || ""}</span>
          </p>
        )}

        <FormControl className="opt_fields" sx={{ width: "100%" }}>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpStyle}
            inputType="tel"
          />
          <br />
          {error && otp.length !== 4 ? (
            <h6 className="err_msg" style={{ textAlign: "center" }}>
              This field is required
            </h6>
          ) : (
            ""
          )}
        </FormControl>

        <Button value="Next" onClick={handleSubmit} />

        {countDown === 0 ? (
          <div className="resend">
            <h4 onClick={handleResendOtp}>Resend Code</h4>
          </div>
        ) : (
          <div className="continue" style={{ marginBottom: "10px" }}>
            <h5>The OTP code will expire in 00 : {countDown}</h5>
          </div>
        )}
      </div>
    </div>
  );
};
export default Verification;
