import React, { useEffect, useState } from "react";
import { InputField, ProductListingCard } from "../../components";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import cardData from "../../data/ProductData.json";
import {
  useDeletePropertyByIdMutation,
  useLazyGetMyProductQuery,
} from "../../service/Product";
import { Loader, errorToast, successToast } from "../../helpers";
import { Category, globalSearch } from "../../types/General";
import { useLazyGetCategoriesQuery } from "../../service/Home";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";

const MyListing = () => {
  const style = generateResponsiveStyle();
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const navigate = useNavigate();
  const [getListing, { isLoading }] = useLazyGetMyProductQuery();
  const [categories, setCategories] = useState<Category[]>([]);

  const [MyListing, setMyListing] = useState<globalSearch[]>([]);

  const [getCategories] = useLazyGetCategoriesQuery();

  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllProperty = async () => {
    try {
      const response = await getListing({
        categoryId: activeCategory,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMyListing(response?.data?.products || []);
      } else {
        setMyListing([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const [deleteProperty] = useDeletePropertyByIdMutation();
  const handleDeleteProperty = async (e: any, id: string) => {
    e.stopPropagation();
    try {
      const res = await deleteProperty({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(res?.message || "");
        getAllProperty();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  useEffect(() => {
    getAllProperty();
  }, [activeCategory]);
  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <div className="flx_dv_prfl">
        <h2>My Listing</h2>
        {/* <Button
          type="button"
          className="hdr_btn"
          onClick={() => navigate("/choose-category")}
          // onClick={() => handleLogin()}
        >
          <figure>
            <img src="/static/images/add_btn.svg" alt="" />
          </figure>
          Add
        </Button> */}
      </div>
      <ul className="categoty_fltrs new_catgry">
        <li
          onClick={() => setActiveCategory("")}
          className={activeCategory === "" ? "fltrs_cat actv_cat" : "fltrs_cat"}
        >
          <h4>All</h4>
        </li>
        {categories?.map((item) => {
          return (
            <li
              key={item?._id}
              onClick={() => setActiveCategory(item?._id)}
              className={
                activeCategory === item?._id
                  ? "fltrs_cat actv_cat"
                  : "fltrs_cat"
              }
            >
              <h4>{item?.name || ""}</h4>
            </li>
          );
        })}
      </ul>
      <div className="listing_sctn">
        {MyListing?.length ? (
          MyListing?.slice(0, 5)?.map((item) => {
            return (
              <ProductListingCard
                setOpenModal={setOpenModal}
                setSelectedId={setSelectedId}
                key={item?._id}
                product_id={item?._id}
                product_image={item?.images[0]}
                category_id={item?.category?._id}
                product_feature={item?.category?.name}
                product_name={item?.name}
                product_price={item?.price}
                location={item?.address}
                listing_date={item?.createdAt}
                booster_applied={item?.boosterApplied}
              />
            );
          })
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/empty.png" alt="" />
            </figure>
            <h3>No Product Found</h3>
          </div>
        )}
      </div>
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Are you sure you want to delete this product?
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={(e: any) => {
                  setOpenModal(false);
                  handleDeleteProperty(e, selectedId);
                }}
              >
                Yes
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MyListing;
