import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button, Pagination, ProductCard } from "../../components";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import "../filters/filter.scss";
import {
  useLazyGetProductsByCategoryIdQuery,
  useLazyGetSubCategoriesQuery,
} from "../../service/category";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { useLocation, useParams } from "react-router-dom";
import { ProductsByCategory, Subcategories } from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import { Value } from "sass";

function valuetext(value: number) {
  return `${value}°C`;
}

const Filters = () => {
  const location = useLocation();
  const { state } = location;
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [favItems, setFavItems] = useState<any[]>([]);
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(1000000000);
  const [value, setValue] = useState<number[]>([1, maxPrice]);
  const [newSliderValue, setNewSliderValue] = useState<number[]>([]);
  const [productsByCategory, setProductsByCategory] = useState<
    ProductsByCategory[]
  >([]);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<Subcategories | null>(null);
  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [productsByCategoryId, { isLoading }] =
    useLazyGetProductsByCategoryIdQuery();
  const [subCategory, GetSubCategoryData] = useLazyGetSubCategoriesQuery();
  console.log(maxPrice, "maxPrice");

  console.log(value, "value");

  const sortingOptiopns = [
    { value: "new", label: "New" },
    { value: "popular", label: "Popular" },
    { value: "lowToHigh", label: "Price Low To High" },
    { value: "highToLow", label: "Price High To Low" },
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const checkFavProducts = () => {
    const fav_data = productsByCategory
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);

    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProducts();
  }, [productsByCategory]);

  const handleBrandToggle = (brand: string) => {
    // Toggle the selected state of the brand
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(brand)) {
        return prevSelectedBrands.filter(
          (selectedBrand) => selectedBrand !== brand
        );
      } else {
        return [...prevSelectedBrands, brand];
      }
    });
  };

  const handleSubcategoryClick = (
    subcategory: React.SetStateAction<Subcategories | null>
  ) => {
    setSelectedSubcategory(subcategory);
    setSelectedBrands([]);
  };
  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const getProductsByCategory = async (id: string) => {
    try {
      const response = await productsByCategoryId({
        categoryId: id,
        page: page,
        minPrice: value[0] || 1,
        maxPrice: value[1] || 1000000000,
        sort: selectedValue ? selectedValue : "",
        brand: selectedBrands ? selectedBrands : [],
        subCategoryId: selectedSubcategory ? selectedSubcategory?._id : "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setProductsByCategory(response?.data?.products || []);
        setTotalCount(response?.data?.count);
        if (!newSliderValue?.length) {
          setValue([1, response?.data?.maxPrice || 10000]);
        }
        setNewSliderValue([1, response?.data?.maxPrice || 10000]);
        setMaxPrice(response?.data?.maxPrice || 100000000);
      } else {
        setProductsByCategory([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handlePriceFilter = () => {
    if (id) {
      getProductsByCategory(id);
    }
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClearFilters = () => {
    setSelectedValue("");
    setSelectedSubcategory(null);
    setSelectedBrands([]);
    setValue([1, 1000000000]);
    setNewSliderValue([]);
  };
  const handleMobileFilters = () => {
    if (id) {
      getProductsByCategory(id);
    }
    setShowFilters(false);
    // setValue([1,maxPrice]);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (id && (token || tempToken)) {
      getProductsByCategory(id);
    }
  }, [
    id,
    selectedSubcategory,
    selectedBrands,
    page,
    selectedValue,
    state,
    tempToken,
  ]);

  useEffect(() => {
    if (id && (token || tempToken)) {
      // setMaxPrice(100000000);
      setValue([1, maxPrice]);
      setSelectedValue("");
      setSelectedSubcategory(null);
      getSubCategory(id);
      setNewSliderValue([]);
    }
  }, [id, tempToken, maxPrice]);

  return (
    <Layout>
      <Loader isLoad={isLoading || GetSubCategoryData?.isLoading} />
      <div className="wrapCls">
        <div className="container">
          <div className="filters">
            <div className="filters_dv">
              <h2>{state || ""}</h2>
              <figure>
                <img
                  src="/static/images/filter_icn.svg"
                  onClick={() => setShowFilters(!showFilters)}
                />
              </figure>
            </div>
            <div className="sub_catMain">
              <div className={showFilters ? "sc_lft mob_dsply" : "sc_lft "}>
                <div className="sub">
                  <div className="filtr_top_hdng">
                    <h4>Sub-Categories</h4>
                    <p onClick={() => handleClearFilters()}>Clear All</p>
                  </div>
                  <ul>
                    {subCategories?.length
                      ? subCategories?.map((item) => {
                          return (
                            <li
                              key={item?._id}
                              onClick={() => handleSubcategoryClick(item)}
                              className={
                                selectedSubcategory === item ? "actv_cat" : ""
                              }
                            >
                              {item?.name || ""}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                {/* Render Brand list only if a subcategory is selected */}
                {selectedSubcategory && selectedSubcategory?.brand?.length ? (
                  <div className="sub">
                    <h3>Brand</h3>
                    <ul>
                      {selectedSubcategory?.brand?.map((brand) => (
                        <li key={brand}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedBrands?.includes(brand)}
                                onChange={() => handleBrandToggle(brand)}
                              />
                            }
                            name={brand}
                            label={brand || ""}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <div className="sub">
                  <h3>Sort By</h3>
                  <ul>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedValue}
                      onChange={handleSortingOptions}
                    >
                      {sortingOptiopns?.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  </ul>
                </div>
                {/* <div className="sub">
                  <h3>Location</h3>
                  <ul>
                    <li>Dubai</li>
                    <li>Abu Dhabi</li>
                  </ul>
                </div> */}

                <div className="sub">
                  <h3>Price</h3>
                  <ul style={{ paddingTop: 0 }}>
                    <li>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        max={maxPrice}
                      />
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                          AED {value[0].toLocaleString("en-IN")}
                        </span>
                        <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                          AED {value[1].toLocaleString("en-IN")}
                        </span>
                      </div>
                      <div className="fltr_btn_sc">
                        <button
                          className="fltr_btnn"
                          onClick={handlePriceFilter}
                        >
                          Apply
                        </button>
                      </div>
                      <div className="mob_fltr_btn">
                        <Button
                          value="Apply Filter"
                          onClick={() => handleMobileFilters()}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={showFilters ? "sc_Ryt hide_prdcts" : "sc_Ryt"}>
                <div className="prodcts_sc">
                  <div className="crds_sec">
                    {productsByCategory?.length ? (
                      productsByCategory?.map((item) => {
                        return (
                          <ProductCard
                            key={item?._id}
                            product_id={item?._id}
                            product_image={item?.images[0]}
                            product_feature={item?.category?.name}
                            product_name={item?.name}
                            product_price={item?.price}
                            booster_applied={item?.boosterApplied}
                            location={item?.address}
                            favItems={favItems}
                            item={item}
                            setFavItems={setFavItems}
                          />
                        );
                      })
                    ) : (
                      <div className="noData top_mt">
                        <figure>
                          <img src="/static/images/empty.png" alt="" />
                        </figure>
                        <h3>No Product Found</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={showFilters ? " hide_prdcts" : ""}>
              {productsByCategory?.length ? (
                <Pagination
                  module={productsByCategory}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Filters;
