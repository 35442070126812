import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./Notifications.scss";
import {
  useLazyClearNotificationQuery,
  useLazyGetNotificationQuery,
} from "../../service/Cms";
import { Loader, successToast } from "../../helpers";
import { NotificationsResponse } from "../../types/General";
import { Pagination } from "../../components";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const navigate = useNavigate();
  const [notification, { isLoading }] = useLazyGetNotificationQuery();
  const [clearNotification] = useLazyClearNotificationQuery();
  const [recentNotificationData, setRecentNotificationData] = useState<
    NotificationsResponse[]
  >([]);
  const [olderNotificationData, setOlderNotificationData] = useState<
    NotificationsResponse[]
  >([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getNotification = async () => {
    try {
      const response = await notification({
        page: page,
      }).unwrap();

      if (response?.statusCode === 200) {
        setRecentNotificationData(response?.data?.recentNotifications || []);
        setOlderNotificationData(response?.data?.olderNotifications || []);
        setTotalCount(response?.data?.oldNotificationCount);
      } else {
        setRecentNotificationData([]);
        setOlderNotificationData([]);
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };

  const handleClearNotifications = async () => {
    try {
      const response = await clearNotification({}).unwrap();

      if (response?.statusCode === 200) {
        successToast("Notifications cleared successfully");
        getNotification();
      }
    } catch (error: any) {
      console.log(error, "error=========>");
    }
  };
  useEffect(() => {
    getNotification();
    // if (window) {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }
  }, [page]);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls container notification_pg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <p>Recent</p>
          {olderNotificationData?.length ? (
            <h4 className="clr_ntfctn_btn" onClick={handleClearNotifications}>
              Clear All Notifications
            </h4>
          ) : null}
        </div>

        {recentNotificationData?.length ? (
          recentNotificationData?.map((item) => {
            return (
              <div
                className="rcnt_notification"
                key={item?._id}
                onClick={() => {
                  if (item?.pushType === 7 || item?.pushType === 11) {
                    navigate("/chat", {
                      state: {
                        type: "notification",
                        roomId: item?.roomId,
                        senderId: item?.senderId,
                        senderImage: item?.senderImage,
                        senderName: item?.senderName,
                        productName: item?.productName,
                      },
                    });
                  } else if (item?.pushType === 12) {
                    navigate(`/view-profile/${item?.senderId}`);
                  } else if (item?.pushType === 13) {
                    navigate("/wishlist");
                  }
                }}
              >
                <div className="ntf_lft">
                  <figure>
                    <img
                      src={
                        item?.senderImage || "/static/images/notification1.png"
                      }
                    />
                  </figure>
                  <div>
                    <h6 style={{ color: "#7dbfdd", paddingBottom: "4px" }}>
                      {item?.title || ""}
                    </h6>
                    <h6>{item?.message || ""}</h6>
                  </div>
                </div>
                <p className="ntf_tm">{moment(item?.createdAt).fromNow()}</p>
              </div>
            );
          })
        ) : (
          <div
            style={{ height: "100%", textAlign: "center", padding: "50px 0" }}
          >
            No Recent Notifications found
          </div>
        )}

        <p>Older Notifications</p>

        {olderNotificationData?.length ? (
          olderNotificationData?.map((item) => {
            return (
              <div
                className="rcnt_notification old_notification"
                key={item?._id}
                onClick={() => {
                  if (item?.pushType === 7 || item?.pushType === 11) {
                    navigate("/chat", {
                      state: {
                        type: "notification",
                        roomId: item?.roomId,
                        senderId: item?.senderId,
                        senderImage: item?.senderImage,
                        senderName: item?.senderName,
                        productName: item?.productName,
                      },
                    });
                  } else if (item?.pushType === 12) {
                    navigate(`/view-profile/${item?.senderId}`);
                  } else if (item?.pushType === 13) {
                    navigate("/wishlist");
                  }
                }}
              >
                <div className="ntf_lft">
                  <figure>
                    <img
                      src={
                        item?.senderImage || "/static/images/notification1.png"
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <h6 style={{ color: "#820000", paddingBottom: "4px" }}>
                      {item?.title || ""}
                    </h6>
                    <h6 style={{ color: "#1A1824" }}>{item?.message || ""}</h6>
                  </div>
                </div>
                <p className="ntf_tm2">{moment(item?.createdAt).fromNow()}</p>
              </div>
            );
          })
        ) : (
          <div
            style={{ height: "100%", textAlign: "center", padding: "50px 0" }}
          >
            No Older Notifications found
          </div>
        )}
        {olderNotificationData?.length ? (
          <Pagination
            module={olderNotificationData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default Notifications;
