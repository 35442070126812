import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import { useLazyGetProfileQuery } from "../../service/Auth";

const LogoHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getProfile, { isLoading }] = useLazyGetProfileQuery();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      console.log("token:header ", token);
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header
      className="logo-header"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <figure
        className="logo_img"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      >
        <img src="/static/images/logo2.png" />
      </figure>
      {/* <h2 onClick={() => navigate("/")}>KRAFO</h2> */}
    </header>
  );
};

export default LogoHeader;
