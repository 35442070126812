import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Box, Button, Modal, Skeleton, TextField } from "@mui/material";
import "./details.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import CloseIcon from "@mui/icons-material/Close";
import {
  useChangeFavStatusMutation,
  useDeletePropertyByIdMutation,
  useLazyGetMyBoosterQuery,
  useLazyGetProductByIdQuery,
  usePostMakeOfferMutation,
} from "../../service/Product";
import {
  CommonBody,
  ProductDetailData,
  RecommendedProductsData,
  boosterPlans,
} from "../../types/General";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import useAuth from "../../hooks/useAuth";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { isNumber } from "../../utils/validations";
import { RWebShare } from "react-web-share";

const ProductDetail = () => {
  const location = useLocation();
  const pathName = window.location.pathname;
  const { state } = location;

  const user = useAuth();
  const { id } = useParams();
  console.log(id, "id");
  const navigate = useNavigate();
  const style = generateResponsiveStyle();
  const [openModal, setOpenModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [offerAmount, setOfferAmount] = useState<number | null>();
  const [productDetail, { isLoading }] = useLazyGetProductByIdQuery();
  const [productData, setProductData] = useState<ProductDetailData>();
  const [selectedImage, setSelectedImage] = useState(
    productData?.images[0] || ""
  );
  const [deleteProduct] = useDeletePropertyByIdMutation();
  const [postMakeOffer] = usePostMakeOfferMutation();

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  const handleMakeOffer = async (id: string) => {
    const data = {
      amount: Number(offerAmount),
    };

    if (!offerAmount) {
      errorToast("Please enter offer amount");
      return;
    }
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await postMakeOffer({
        productId: id,
        encryptedBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        setRoomId(res?.data?.chatRoomId);
        setOpenOfferModal(false);
        setOfferAmount(null);
        successToast(res?.message || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleDeleteProduct = async (id: string) => {
    try {
      const res = await deleteProduct({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(res?.message || "");
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getProductDetail = async () => {
    try {
      const response = await productDetail({
        product_id: id,
        searchHistory: state === "saveSearchHistory" ? true : false,
      }).unwrap();
      if (response?.statusCode === 200) {
        setProductData(response?.data);
        setRoomId(response?.data?.chatRoomId || "");
        setSelectedId(response?.data?._id);
        setSelectedImage(response?.data?.images[0]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [myBooster] = useLazyGetMyBoosterQuery();
  const [myBoosterState, setMyBoosterState] = useState<boosterPlans>();

  const getMyBooster = async () => {
    try {
      const result = await myBooster({}).unwrap();
      if (result?.statusCode === 200) {
        setMyBoosterState(result?.data || null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const token = getFromStorage(STORAGE_KEYS.token);
  const dispatch = useAppDispatch();

  const handleFav = async (id: string | undefined) => {
    if (token) {
      const data = {
        productId: id,
        isFav: !productData?.isFav,
      };
      console.log(data, "data");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      try {
        const response = await changeFavStatus(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          getProductDetail();
          // setSelected(!productData?.isFav);
        } else {
          errorToast("Please try again");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  useEffect(() => {
    if (id) {
      getProductDetail();
      getMyBooster();
    }
  }, [id]);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="container">
          <h1 className="prdct_dtl_hdng">Product Detail</h1>
          <div className="detail_main">
            <div className="dLeft">
              {/* {productData && ( */}
              {selectedImage ? (
                <figure className="prdct_img_sc">
                  <img src={selectedImage} alt="Product" />
                </figure>
              ) : (
                <Skeleton className="prdct_img_sc" variant="rectangular" />
              )}

              <div className="p_Imgs">
                {productData?.images?.length
                  ? productData?.images?.map((item) => (
                      <figure onClick={() => handleImageClick(item)}>
                        <img src={item} alt="Product" />
                      </figure>
                    ))
                  : null}
              </div>
            </div>
            <div className="dRyt">
              <div className="rightCard">
                <div className="auto_ryt">
                  <h3>{productData?.category?.name || ""}</h3>
                  <h2>
                    {" "}
                    {productData?.name && productData?.name?.length > 35
                      ? productData?.name?.slice(0, 35) + "...."
                      : productData?.name || ""}
                  </h2>
                  <p className="p2">
                    Posted on:{" "}
                    {moment(productData?.createdAt).format("MMM Do YYYY") || ""}
                  </p>
                  <p>{productData?.address || ""}</p>
                </div>
                <div className="auto_left">
                  <div className="fig">
                    <RWebShare
                      data={{
                        text: "Product",
                        url: `https://dwmweb.appgrowthcompany.com/product-detail/${productData?._id}`,
                        title: "Share",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <figure>
                        <img src="/static/images/share.svg" alt="Product" />
                      </figure>
                    </RWebShare>

                    {user?._id ===
                    productData?.userId ? null : productData?.isFav ? (
                      <figure onClick={() => handleFav(productData?._id)}>
                        <img
                          src="/static/images/filled_heart.svg"
                          alt="Product"
                        />
                      </figure>
                    ) : (
                      <figure onClick={() => handleFav(productData?._id)}>
                        <img src="/static/images/heart.svg" alt="Product" />
                      </figure>
                    )}
                  </div>
                  <h2>
                    AED {productData?.price.toLocaleString("en-IN") || "0"}
                  </h2>
                </div>
              </div>
              <div className="rightCard2">
                {state?.page === "my_listing" ? null : (
                  <div className="card_top">
                    <div className="cd_lft">
                      <figure>
                        <img
                          src={
                            productData?.user?.image ||
                            "/static/images/empty_user.png"
                          }
                          alt=""
                        />
                      </figure>
                      <div className="username">
                        <h3>{productData?.user?.fullName || ""}</h3>
                        <h4>
                          Member since{" "}
                          {moment(productData?.user?.createdAt).format(
                            "MMM YYYY"
                          ) || ""}
                        </h4>
                      </div>
                    </div>
                    <div className="cd_ryt">
                      <p
                        onClick={() => {
                          user
                            ? navigate(`/view-profile/${productData?.userId}`)
                            : dispatch(
                                toggleAuthModal({
                                  isAuthModalVisible: true,
                                  ModalType: "login",
                                })
                              );
                        }}
                      >
                        View Profile
                      </p>
                    </div>
                  </div>
                )}
                {state?.page === "my_listing" ? (
                  <>
                    {productData?.dynamicFields?.some((item: {}) =>
                      Object.keys(item).some(
                        (key) => key !== undefined && key !== null
                      )
                    ) ? (
                      <div className="more_details">
                        <h4>More Details</h4>
                        <ul>
                          {productData?.dynamicFields?.map(
                            (
                              item:
                                | { [s: string]: React.ReactNode }
                                | ArrayLike<React.ReactNode>
                            ) => {
                              return (
                                <li>
                                  <p>{Object.keys(item)}</p>
                                  <p>{Object.values(item)}</p>
                                </li>
                              );
                            }
                          )}
                          <li className="boosterValidity">
                            <p>Booster</p>{" "}
                            <p>{myBoosterState?.validity || ""}</p>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                    <div className="card_btm">
                      <Button
                        onClick={() =>
                          navigate(
                            `/add-product/${productData?.category?._id}/${productData?._id}`
                          )
                        }
                      >
                        Edit Detail
                      </Button>
                      <Button onClick={() => setOpenModal(true)}>
                        Delete Product
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="card_btm">
                    <Button
                      onClick={() => {
                        user
                          ? navigate("/chat", {
                              state: {
                                type: "chat",
                                roomId: roomId
                                  ? roomId
                                  : `chat_${productData?._id}_${user?._id}`,
                                senderId: productData?.user?._id,
                                senderImage: productData?.user?.image,
                                senderName: productData?.user?.fullName,
                                productName: productData?.name,
                                productId: productData?._id,
                              },
                            })
                          : dispatch(
                              toggleAuthModal({
                                isAuthModalVisible: true,
                                ModalType: "login",
                              })
                            );
                      }}
                    >
                      <figure>
                        <img src="/static/images/chat.svg" alt="Product" />
                      </figure>
                      Chat
                    </Button>
                    {productData?.makeAnOffer ? (
                      <Button
                        className="makeOffer"
                        onClick={() => {
                          user
                            ? setOpenOfferModal(true)
                            : dispatch(
                                toggleAuthModal({
                                  isAuthModalVisible: true,
                                  ModalType: "login",
                                })
                              );
                        }}
                        disabled={!productData?.makeAnOffer}
                      >
                        <figure className="offr_img">
                          <img src="/static/images/offer.svg" alt="Product" />
                        </figure>
                        Make an offer
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>
              {state?.page !== "my_listing" &&
              productData?.dynamicFields?.some((item: {}) =>
                Object.keys(item).some(
                  (key) => key !== undefined && key !== null
                )
              ) ? (
                <div className="more_Info">
                  <h4>More Details</h4>
                  <ul>
                    {productData?.dynamicFields?.map(
                      (
                        item:
                          | { [s: string]: React.ReactNode }
                          | ArrayLike<React.ReactNode>
                      ) => {
                        return (
                          <li>
                            <p>{Object.keys(item)}</p>
                            <p>{Object.values(item)}</p>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ) : null}
              <div className="map_div">
                <figure>
                  <img src="/static/images/map.png" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="description">
            <h2>Description</h2>
            <p>{productData?.description || ""}</p>
          </div>
        </div>
      </div>
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Are you sure you want to delete this product?
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteProduct(selectedId);
                }}
              >
                Yes
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openOfferModal}
        onClose={() => setOpenOfferModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="make_an_offer">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenOfferModal(false)} />
            </div>
            <h2
              style={{
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Make an Offer
            </h2>
            <div className="offers_showing">
              <div
                className={
                  offerAmount === Number(productData?.price) - 10
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  setOfferAmount(Number(productData?.price) - 10);
                }}
              >
                AED {productData?.price ? productData?.price - 10 : ""}
              </div>
              <div
                className={
                  offerAmount === Number(productData?.price)
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  setOfferAmount(Number(productData?.price));
                }}
              >
                {" "}
                AED {productData?.price || ""}
              </div>
              <div
                className={
                  offerAmount === Number(productData?.price) + 10
                    ? "active_price ofrs_dv"
                    : "ofrs_dv"
                }
                onClick={() => {
                  setOfferAmount(Number(productData?.price) + 10);
                }}
              >
                AED {productData?.price ? productData?.price + 10 : ""}
              </div>
            </div>
            <div className="make_ofr">
              <TextField
                className="ofr_inpt"
                id="standard-basic"
                placeholder="Amount"
                variant="standard"
                value={
                  offerAmount === null || offerAmount === undefined
                    ? ""
                    : offerAmount.toString()
                }
                inputProps={{ maxLength: 8 }}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (isNumber(inputValue)) {
                    setOfferAmount(
                      inputValue === "" ? null : Number(inputValue)
                    );
                  }
                }}
              />
            </div>
            <Button
              onClick={() => {
                handleMakeOffer(id as string);
              }}
            >
              Send
            </Button>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default ProductDetail;
