import AddProduct from "./addProduct";
import Chat from "./chat";
import ChooseCategory from "./chooseCategory";
import Filters from "./filters";
import HomePage from "./home";
import ProductDetail from "./productDetail";
import Profile from "./profile";
import Wishlist from "./wishlist";
import Notifications from "./notifications";
import SeeAll from "./seeAll";
import ContactUs from "./contactUs";
import AboutUs from "./aboutUs";
import Faqs from "./faq";
import PrivacyPolicy from "./privacyPolicy";
import ViewProfile from "./viewProfile";

export default {
  HomePage,
  ProductDetail,
  ChooseCategory,
  Wishlist,
  Profile,
  AddProduct,
  Chat,
  Filters,
  Notifications,
  SeeAll,
  ContactUs,
  AboutUs,
  Faqs,
  PrivacyPolicy,
  ViewProfile,
};
