import React, { Dispatch, SetStateAction, useState } from "react";
import { Input } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, InputField } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { UploadMedia } from "../../utils/uploadMedia";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  usePostChangePasswordMutation,
  usePostSetProfileMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { setCredentials } from "../../reducers/authSlice";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const ChangePassword = ({ closeModal, setPhone }: props) => {
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [changePassword, { isLoading }] = usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number."
        )
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let data = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };
      console.log(data, "body");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await changePassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "Password is updated successfully");
          closeModal();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross ">
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2 style={{ marginBottom: "15px" }}>Change Password</h2>

        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder="Old Password"
            password
            name="oldPassword"
            id="oldPassword"
            value={formik.values.oldPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
          <InputField
            placeholder="New Password"
            password
            name="newPassword"
            id="newPassword"
            value={formik.values.newPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <InputField
            placeholder="Confirm Password"
            password
            name="confirmPassword"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />

          <Button
            value="Submit"
            // onClick={handleContinue}
          />
        </form>
      </div>
      <div style={{ marginBottom: 20 }} />
    </div>
  );
};

export default ChangePassword;
