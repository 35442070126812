import React from "react";

const DownloadApp = () => {
  return (
    <section className="dwnld_bnr">
      <div className="mn_bnr_dwnld container">
        <div className="dwnld_bnr_lft">
          {/* <h2>Krafo App</h2> */}
          <figure className="logo_img2">
            <img src="/static/images/logo.png" />
          </figure>
          <p>
            Buy or Sell anything using the app on your mobile. Find Jobs, Homes,
            Services and more.
          </p>
          <div className="plystr_btns">
            <figure>
              <img src="/static/images/play_store.png" alt="" />
            </figure>
            <figure>
              <img src="/static/images/app_store.png" alt="" />
            </figure>
          </div>
        </div>
        <div className="dwnld_bnr_ryt">
          <figure>
            <img src="/static/images/bnr_phone.png" alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
