import { useMemo } from "react";
import { useAppSelector } from "./store";

import { address } from "../reducers/generalSlice";

function useAddress() {
  const addressData = useAppSelector(address);
  return useMemo(() => addressData, [addressData]);
}

export default useAddress;
