// @ts-nocheck
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./ViewProfile.scss";
import { Loader, errorToast, successToast } from "../../helpers";
import { Category, CommonBody, ProfileData } from "../../types/General";
import { useLazyGetCategoriesQuery } from "../../service/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";

import { Button, ProductCard } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import {
  useLazyGetProfileByIdQuery,
  usePostFollowUnfollowMutation,
} from "../../service/Product";
import { useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { useAddReportMutation } from "../../service/Cms";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const ViewProfile = () => {
  const style = generateResponsiveStyle();
  const { id } = useParams();
  const [favItems, setFavItems] = useState<any[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [userData, setUserData] = useState<ProfileData>();
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [report, setReport] = useState<string>("");
  const [addReport] = useAddReportMutation();
  const [getCategories] = useLazyGetCategoriesQuery();
  const [getProfileById, { isLoading }] = useLazyGetProfileByIdQuery();
  const [postFollow, GetFollowData] = usePostFollowUnfollowMutation();
  const [error, setError] = useState<boolean>(false);
  const [open1, setOpen1] = useState(false);
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = ["Share Profile", "Report"];

  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const getProfile = async (id: string) => {
    try {
      const response = await getProfileById({
        user_id: id,
        category_id: activeCategory,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data || {});
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handleFollowing = async (id: string) => {
    try {
      const response = await postFollow({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        try {
          const response = await getProfileById({
            user_id: id,
            category_id: activeCategory,
          }).unwrap();
          if (response?.statusCode === 200) {
            setUserData(response?.data || {});
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const checkFavProperties = () => {
    const fav_data = userData?.products
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  //handle report
  const handleReport = async (id: string) => {
    setError(true);
    if (!report) {
      return;
    }
    let data = {
      reason: report,
    };
    console.log(data, "data");

    try {
      console.log("try");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      console.log("encryptedBody: ", encryptedBody);
      const response = await addReport({ id, encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        console.log("response: ", response);
        successToast("Report Sent Successfully");
        setReport("");
        setError(false);
        setOpen1(false);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    checkFavProperties();
  }, [userData?.products]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (id) {
      getProfile(id);
    }
  }, [activeCategory]);
  return (
    <Layout>
      <Loader isLoad={isLoading || GetFollowData?.isLoading} />
      <div className="container vw_prfl">
        <div className="vw_lft">
          <div className="vw_lft_top">
            <figure>
              <img
                src={
                  userData?.user?.image || "/static/images/user_placeholder.png"
                }
              />
            </figure>
            <h3>{userData?.user?.fullName || ""}</h3>
          </div>
          <ul className="vw_ul">
            <li>
              <p>
                {" "}
                Total Products <span> {userData?.products?.length || 0}</span>
              </p>
            </li>
            <li>
              <p>
                {" "}
                Total Followers{" "}
                <span> {userData?.followCount?.followersCount || 0}</span>
              </p>
            </li>
            <li>
              <p>
                {" "}
                Total Following{" "}
                <span>{userData?.followCount?.followingCount || 0}</span>
              </p>
            </li>
          </ul>
          <Button
            value={userData?.isFollow ? "Unfollow" : "Follow"}
            onClick={() => handleFollowing(userData?.user?._id)}
          ></Button>
        </div>
        <div className="vw_ryt">
          <div className="vw_prfl_hdng">
            <h2>My Products</h2>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                position: "relative",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="account_item"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "12ch",
                },
              }}
            >
              <RWebShare
                data={{
                  text: "Profile",
                  url: `https://dwmweb.appgrowthcompany.com/view-profile/${id}`,
                  title: "Share Profile",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <MenuItem>Share Profile</MenuItem>
              </RWebShare>

              <MenuItem onClick={() => setOpen1(true)}>Report</MenuItem>
            </Menu>
          </div>
          <ul className="categoty_fltrs">
            <li
              onClick={() => setActiveCategory("")}
              className={
                activeCategory === "" ? "fltrs_cat actv_cat" : "fltrs_cat"
              }
            >
              <h4>All</h4>
            </li>
            {categories?.map((item) => {
              return (
                <li
                  key={item?._id}
                  onClick={() => setActiveCategory(item?._id)}
                  className={
                    activeCategory === item?._id
                      ? "fltrs_cat actv_cat"
                      : "fltrs_cat"
                  }
                >
                  <h4>{item?.name || ""}</h4>
                </li>
              );
            })}
          </ul>
          <div className="prdcts_sc">
            {userData?.products?.length ? (
              userData?.products?.map((item) => {
                return (
                  <ProductCard
                    key={item?._id}
                    product_id={item?._id}
                    product_image={item?.images[0]}
                    product_feature={item?.category?.name}
                    product_name={item?.name}
                    product_price={item?.price}
                    location={item?.address}
                    setFavItems={setFavItems}
                    favItems={favItems}
                    item={item}
                    booster_applied={item?.boosterApplied}
                  />
                );
              })
            ) : (
              <div className="noData top_mt">
                <figure>
                  <img src="/static/images/empty.png" alt="" />
                </figure>
                <h3>No Product Found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        // BackdropClick={false}

        open={open1}
        onClose={() => setOpen1(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpen1(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Why you want to report this user?
            </h2>
            <div className="logut_flx1">
              <TextField
                style={{ margin: 0 }}
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder="Your reason"
                multiline
                rows={4}
                fullWidth
                name="description"
                id="description"
                value={report}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    setReport(val.target.value);
                  }
                }}
                // onBlur={formik.handleBlur}
                // helperText={
                //   formik.touched.description && formik.errors.description
                // }
              />
            </div>
            {error && !report ? (
              <h6 className="err_msg">This field is required</h6>
            ) : (
              ""
            )}
            <div style={{ width: "50%", margin: "auto" }}>
              <Button value="Report" onClick={() => handleReport(id)} />
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default ViewProfile;
