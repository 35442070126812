// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import "./Components.scss";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import useAuth from "../hooks/useAuth";
import { useAppDispatch } from "../hooks/store";
import { toggleAuthModal } from "../reducers/generalSlice";
import { useChangeFavStatusMutation } from "../service/Product";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../helpers";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";

type ProductType = {
  product_id: string;
  product_image: string;
  product_feature: string;
  product_name: string;
  product_price: number;
  location: string;
  wishlist?: boolean;
  booster_applied: boolean;
  favItems?: any[];
  setFavItems?: Dispatch<SetStateAction<any[]>>;
  item?: any;
};

const ProductCard = ({
  product_id,
  product_image,
  product_feature,
  product_name,
  product_price,
  location,
  wishlist,
  favItems,
  setFavItems,
  booster_applied,
  item,
}: ProductType) => {
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();
  const [changeFavStatus] = useChangeFavStatusMutation();
  const [selected, setSelected] = useState<boolean | undefined>(false);

  useEffect(() => {
    setSelected(wishlist);
  }, [wishlist]);

  const token = getFromStorage(STORAGE_KEYS.token);

  const handleFav = async (e: any, item: RecommendedProductsData) => {
    e.stopPropagation();
    if (token) {
      const data = {
        productId: item?._id,
        isFav: favItems?.includes(item) ? false : true,
      };
      console.log(data, "data");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      try {
        const response = await changeFavStatus(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (favItems?.includes(item)) {
            let indexToDelete = favItems.indexOf(item);
            favItems.splice(indexToDelete, 1);
            setFavItems([...favItems]);
          } else {
            favItems?.push(item);
            setFavItems([...favItems]);
          }
        } else {
          errorToast("Please try again");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  return (
    <div
      className="prdct_crd"
      onClick={() => navigate(`/product-detail/${product_id}`)}
    >
      <figure className="prdct_img">
        <img src={product_image || ""} alt="" />
        {booster_applied ? (
          <div className="boosterIcon">
            <figure>
              <img src="/static/images/booster.png" alt="" />
            </figure>
          </div>
        ) : null}

        <div className="fvrt_img">
          {favItems?.includes(item) ? (
            <FavoriteIcon onClick={(e) => handleFav(e, item)} />
          ) : (
            <FavoriteBorderRoundedIcon onClick={(e) => handleFav(e, item)} />
          )}
        </div>
      </figure>
      <div className="prdct_dtl">
        <h4>{product_feature || ""}</h4>
        <h4>AED {product_price.toLocaleString("en-IN") || 0}</h4>
      </div>
      <h3>
        {product_name?.length > 35
          ? product_name.slice(0, 35) + "...."
          : product_name || ""}
      </h3>
      <p>{location || ""}</p>
    </div>
  );
};

export default ProductCard;
