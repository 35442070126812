import React, { useEffect, useState } from "react";
import { Button, InputField } from "../../components";
import { Box, FormControl, Input, Modal, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia } from "../../utils/uploadMedia";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../helpers";
import {
  useJustSendOtpMutation,
  useJustVerifyOtpMutation,
  useLazyGetProfileQuery,
  usePostSetProfileMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal, verificationData } from "../../reducers/generalSlice";
import { setCredentials } from "../../reducers/authSlice";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import OTPInput from "react-otp-input";
import { isString } from "../../utils/validations";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};

const EditProfile = () => {
  const user = useAuth();
  const style = generateResponsiveStyle();
  const dispatch = useAppDispatch();
  const [isPhone, setIsPhone] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [openModal, setOpenModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+971");
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [oldNum, setOldNum] = useState<string>("");
  const [oldEmail, setOldEmail] = useState<string>("");
  const [getProfile, GetProfileData] = useLazyGetProfileQuery();
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [oldCode, setOldCode] = useState("+971");

  const [updateProfile, { isLoading }] = usePostSetProfileMutation();
  const [sendOtp] = useJustSendOtpMutation();
  const [verifyOtp] = useJustVerifyOtpMutation();

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setOldCode(`+${country?.dialCode}`);
    setPhoneCode(`+${country?.dialCode}`);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  //for resend otp
  const handleResendOtp = async () => {
    let data = {
      ...(isPhone
        ? {
            phone: formik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: formik.values.email,
          }),
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await sendOtp(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message || "");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  // for sending otp
  const handleDataChange = async (val: number) => {
    let data = {
      ...(val === 1
        ? {
            phone: formik.values.phone,
            countryCode: phoneCode,
          }
        : {
            key: formik.values.email,
          }),
    };

    if (
      formik.values?.phone?.length > 6 ||
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        formik.values.email
      )
    ) {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          setOpenModal(true);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  //for otp verification
  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);
      // setOpen(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const data = {
        ...(!isPhone
          ? { key: formik.values.email, code: otp }
          : {
              key: formik.values.phone,
              countryCode: phoneCode,
              code: otp,
            }),
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await verifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (isPhone) {
            setOldNum(formik.values.phone);
            setOldCode(phoneCode);
            setPhoneVerified(true);
          } else {
            setOldEmail(formik.values.email);
            setEmailVerified(true);
          }
          setOpenModal(false);
          setOtp("");
          successToast(response?.message || "");
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  // for update profile
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      fullName: user?.fullName || "",
      phone: user?.phone || "",
      address: user?.address || "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      fullName: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(40, "Maximum 40 character are allowed"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      address: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        fullName: formik.values.fullName,
        address: formik.values.address,
        // city: "",
        // state: "",
        // country:  "",
        // pinCode:  "",
        // latitude:"",
        // longitude: `${geometry?.longitude || 12}`,
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: phoneCode,
        image: image,
        isProfileComplete: true,
      };

      console.log(data, "dataaa@@");

      const newNumber = formik.values?.phone !== oldNum;
      const newEmail = formik.values?.email !== oldEmail;

      if (newNumber || phoneCode !== oldCode || !phoneVerified) {
        errorToast("Please verify phone number first");
      } else if (newEmail || !emailVerified) {
        errorToast("Please verify email first");
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateProfile(encryptedBody).unwrap();
          console.log(response, "response");

          if (response?.statusCode === 200) {
            successToast(response?.message || "Profile updated successfully");
            const token = getFromStorage(STORAGE_KEYS.token);
            dispatch(
              setCredentials({
                token: JSON.parse(`${token}`),
                user: response?.data || null,
              })
            );
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    getUserDetails();
    setPhoneCode(user?.countryCode || "+971");
    setOldCode(user?.countryCode || "+971");
    setOldEmail(user?.email || "");
    setImage(user?.image || "");
    setEmailVerified(user ? user.isEmailVerify : false);
    setPhoneVerified(user ? user.isPhoneVerify : false);
    setOldNum(user ? user?.phone : "+971");
  }, [user]);

  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  useEffect(() => {
    if (openModal) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, openModal]);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <h2>Profile</h2>
      <p> Manage your profile details</p>
      <div className="image">
        <label htmlFor="icon-button-file">
          <Input
            sx={{ display: "none" }}
            id="icon-button-file"
            type="file"
            inputProps={{
              accept: "image/png,image/jpeg",
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files && event.target.files[0]) {
                setImage(URL.createObjectURL(event.target.files[0]));
                handleImageUpload(event.target.files[0]);
              }
            }}
          />

          <figure>
            {!image ? (
              <img
                className="img_style2"
                src="/static/images/empty_user.png"
                alt=""
              />
            ) : (
              <img className="img_style" src={image} alt="" />
            )}
          </figure>
          <AddIcon />
        </label>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form_control">
          <h3>Full Name</h3>
          <InputField
            placeholder="Full Name"
            name="fullName"
            id="fullName"
            inputProps={{ maxLength: 40 }}
            value={formik.values.fullName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
        </div>
        <div className="form_control">
          <h3>Email Id</h3>
          <div className="vrfctn_dv2">
            <InputField
              placeholder="Email Id"
              name="email"
              id="email"
              value={formik.values.email}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.email && formik.errors.email}
            />
            {/* {(formik.values?.email !== user?.email &&
                            !emailVerified) ||
                        (formik.values?.email === user?.email &&
                            !emailVerified) ? (
                            <div>
                                {user && (
                                    <div>
                                        <button
                                            className={
                                                !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                                                    formik.values.email,
                                                )
                                                    ? "vrfctn_btn2"
                                                    : "vrfctn_btn"
                                            }
                                            type='button'
                                            onClick={() => {
                                                setIsPhone(false);
                                                handleDataChange(0);
                                            }}
                                        >
                                            {formik.values?.email?.length === 0
                                                ? ""
                                                : !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                                                      formik.values.email,
                                                  )
                                                ? "Invalid Email"
                                                : "Verify"}
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <figure className='verified'>
                                    <img
                                        src='/static/images/phonetick.png'
                                        alt=''
                                    />
                                </figure>
                            </div>
                        )} */}
            {formik.values?.email?.length > 0 &&
            formik.values?.email === oldEmail &&
            emailVerified ? (
              <div>
                <figure className="verified">
                  <img src="/static/images/phonetick.png" alt="" />
                </figure>
              </div>
            ) : (
              <div>
                {user && (
                  <div>
                    <button
                      className={
                        !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                          formik.values.email
                        )
                          ? "vrfctn_btn2"
                          : "vrfctn_btn"
                      }
                      type="button"
                      onClick={() => {
                        setIsPhone(false);
                        handleDataChange(0);
                      }}
                    >
                      {formik.values?.email?.length === 0
                        ? ""
                        : !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                            formik.values.email
                          )
                        ? "Invalid Email"
                        : "Verify"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="form_control">
          <h3>Phone number</h3>
          <div className="vrfctn_dv">
            <PhoneInput
              country={"in"}
              value={phoneCode + formik.values.phone}
              onChange={(phone: any, country: any) =>
                handleChangePhone(phone, country)
              }
              onBlur={formik.handleBlur}
              placeholder="0 (000) 000-000"
              enableSearch={false}
              inputStyle={{
                width: "100%",
                backgroundColor: "#EBEBEB",
              }}
            />
            {
              // ((formik.values?.phone !== user?.phone ||
              //   phoneCode !== user?.countryCode) &&
              //   !phoneVerified) ||
              formik.values?.phone?.length > 0 &&
              formik.values?.phone === oldNum &&
              phoneCode === oldCode &&
              phoneVerified ? (
                <div>
                  <figure className="verified">
                    <img src="/static/images/phonetick.png" alt="" />
                  </figure>
                </div>
              ) : (
                <div>
                  {user && (
                    <div>
                      <button
                        className={
                          formik.values?.phone?.length === 0
                            ? "vrfctn_btn2"
                            : formik.values?.phone?.length < 6
                            ? "vrfctn_btn2"
                            : "vrfctn_btn"
                        }
                        type="button"
                        onClick={() => {
                          setIsPhone(true);
                          handleDataChange(1);
                        }}
                      >
                        {formik.values?.phone?.length === 0
                          ? ""
                          : formik.values?.phone?.length < 6
                          ? "Invalid Number"
                          : "Verify"}
                      </button>
                    </div>
                  )}
                </div>
              )
            }
          </div>
          {formik.touched.phone && formik.errors.phone ? (
            <h6 className="err_msg">
              {formik.touched.phone && formik.errors.phone}
            </h6>
          ) : (
            ""
          )}
          <div style={{ marginBottom: 10 }} />
        </div>
        <div className="form_control">
          <h3>Location</h3>
          <InputField
            placeholder="Location"
            name="address"
            id="address"
            value={formik.values.address}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.address && formik.errors.address}
          />
        </div>
        <div className="btn">
          <Button value="Edit Detail" />
        </div>
      </form>
      <Modal
        open={openModal}
        onClose={closeModal}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2>Verification</h2>

            {isPhone ? (
              <p>
                We have just sent you a verification code via{" "}
                <span>
                  {(phoneCode.includes("+") ? "" : "+") +
                    phoneCode +
                    formik.values.phone || ""}
                </span>
              </p>
            ) : (
              <p>
                We have sent an OTP code to your email{" "}
                <span>{formik.values.email || ""}</span>
              </p>
            )}

            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
              <br />
              {error && otp.length !== 4 ? (
                <h6 className="err_msg" style={{ textAlign: "center" }}>
                  This field is required
                </h6>
              ) : (
                ""
              )}
            </FormControl>

            <Button value="Next" onClick={handleSubmit} />

            {countDown === 0 ? (
              <div className="resend">
                <h4 onClick={handleResendOtp}>Resend Code</h4>
              </div>
            ) : (
              <div className="continue" style={{ marginBottom: "10px" }}>
                <h5>The OTP code will expire in 00 : {countDown}</h5>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditProfile;
