import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import React, { useEffect, useMemo, useState } from "react";
import { InputField } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useAddAddressMutation,
  useDeleteAddressByIdMutation,
  useLazyGetAddressByIdQuery,
  useLazyGetAddressQuery,
  usePostSelectAddressMutation,
  useUpdateAddressMutation,
} from "../../service/manageAddress";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { AddressRes, CommonBody } from "../../types/General";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "../../hooks/store";
import { addressData } from "../../reducers/generalSlice";
import useAuth from "../../hooks/useAuth";
import { setCredentials } from "../../reducers/authSlice";
import { useLazyGetProfileQuery } from "../../service/Auth";

const ManageAddresses = () => {
  const userData = useAuth();
  const dispatch = useAppDispatch();
  const style = generateResponsiveStyle();
  const token = getFromStorage(STORAGE_KEYS.token);
  const [value, setValue] = useState<string>(userData?.defaultAddressId || "");
  const [activeButton, setActiveButton] = useState("1");
  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
  const [allAddress, setAllAddress] = useState<AddressRes[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const [isAdd, setIsAdd] = useState<boolean>(true);

  const [addAddress] = useAddAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [getAddress] = useLazyGetAddressQuery();
  const [deleteAddressMethod] = useDeleteAddressByIdMutation();
  const [addressById] = useLazyGetAddressByIdQuery();
  const [selectDefaultAddress] = usePostSelectAddressMutation();
  const [getProfile, { isLoading }] = useLazyGetProfileQuery();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: "",
      house: "",
      landmark: "",
    },

    validationSchema: Yup.object({
      address: Yup.string()
        .required("This field is required.")
        .label("Address")
        .min(3, "Minimum 3 characters are required"),
      house: Yup.string()
        .required("This field is required.")
        .min(3, "Minimum 3 characters are required"),
      landmark: Yup.string()
        .required("This field is required.")
        .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const data = {
        address: formik.values.address,
        city: formik.values.house,
        landmark: formik.values.landmark,
        latitude: "30.713061321086474",
        longitude: "76.70939868316054",
        saveAs:
          activeButton === "1"
            ? "Home"
            : activeButton === "2"
            ? "Work"
            : "Other",
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        if (showAddAddress && isAdd) {
          const res = await addAddress(encryptedBody).unwrap();
          if (res?.statusCode === 200) {
            if (res?.data) {
              successToast("Address Added Successfully");
              formik.resetForm();
              setShowAddAddress(false);
              getAllAddress();
            }
          }
        } else {
          const res = await updateAddress({
            address_id: selectedId,
            encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            if (res?.data) {
              setSelectedId(selectedId);
              getAllAddress();
              setShowAddAddress(false);
              successToast("Address Updated Successfully");
              formik.resetForm();
            }
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  const handleDeleteAddress = async (id: string) => {
    try {
      const res = await deleteAddressMethod({ address_id: id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(res?.message || "");
        getAllAddress();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleEditAddress = async (id: string) => {
    try {
      const res = await addressById({ address_id: id }).unwrap();
      if (res?.statusCode === 200) {
        setIsAdd(false);
        formik.setFieldValue("address", res?.data?.address);
        formik.setFieldValue("house", res?.data?.city);
        formik.setFieldValue("landmark", res?.data?.landmark);
        setSelectedId(res?.data?._id);
        setActiveButton(
          res?.data?.saveAs === "Home"
            ? "1"
            : res?.data?.saveAs === "Work"
            ? "2"
            : "3"
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleDefaultAddress = async () => {
    if (!value) {
      errorToast("Please select default address");
      return;
    }
    try {
      const res = await selectDefaultAddress({
        id: value,
        isDefault: true,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(res?.message);
        getAllAddress();
        getUserDetails();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllAddress = async () => {
    try {
      const response = await getAddress({}).unwrap();
      if (response?.statusCode === 200) {
        setAllAddress(response?.data?.address || []);
        dispatch(
          addressData({
            address: response?.data?.address,
          })
        );
        setSelectedId(response?.data?.address[0]?._id);
      } else {
        setAllAddress([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleAddAddress = () => {
    setIsAdd(true);
    setShowAddAddress(true);
    setActiveButton("1");
    formik.setFieldValue("address", "");
    formik.setFieldValue("house", "");
    formik.setFieldValue("landmark", "");
    setSelectedId("");
  };

  useEffect(() => {
    if (token) {
      getAllAddress();
    }
  }, []);

  // useMemo(() => {
  //   if (userData?.defaultAddressId === null && allAddress?.length === 1) {
  //     setValue(allAddress[0]?._id);
  //     handleDefaultAddress();
  //   }
  // }, [allAddress]);

  return (
    <>
      {showAddAddress ? (
        <div className="main new_addrss">
          <div className="flx_dv_prfl">
            <h2> {isAdd ? "Add New Address" : "Edit Address"}</h2>
            <Button
              type="button"
              className="hdr_btn back"
              onClick={() => setShowAddAddress(false)}
            >
              {/* <figure>
                <img src="/static/images/add_btn.svg" alt="" />
              </figure> */}
              Back
            </Button>
          </div>

          <figure>
            <img src="/static/images/map.png" alt="" />
          </figure>
          {/* <div className="adrss_mn">
            <FmdGoodIcon />
            <h4>Lower Manhattan</h4>
          </div> */}
          {/* <h5>8591 Ramblewood St. Lower Manhattan Grovetown, GA 30813</h5> */}
          <form onSubmit={formik.handleSubmit}>
            <InputField
              placeholder="Address"
              name="address"
              id="address"
              value={formik.values.address}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.address && formik.errors.address}
            />

            <InputField
              placeholder="House / Flat / Block No."
              name="house"
              id="house"
              value={formik.values.house}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.house && formik.errors.house}
            />
            <InputField
              placeholder="Landmark"
              name="landmark"
              id="landmark"
              value={formik.values.landmark}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              helperText={formik.touched.landmark && formik.errors.landmark}
            />
            <h4>Save As</h4>
            <div className="save_as">
              <div
                className={
                  activeButton === "1"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
                onClick={() => setActiveButton("1")}
              >
                Home
              </div>
              <div
                onClick={() => setActiveButton("2")}
                className={
                  activeButton === "2"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
              >
                Work
              </div>
              <div
                onClick={() => setActiveButton("3")}
                className={
                  activeButton === "3"
                    ? "active_btn saveas_btns"
                    : "saveas_btns"
                }
              >
                Other
              </div>
            </div>
            <button>{isAdd ? "Save" : "Update"} Address</button>
          </form>
        </div>
      ) : (
        <div className="main" style={{ padding: 0 }}>
          <div className="flx_dv_prfl" style={{ padding: "20px" }}>
            <h2>Manage Addresses</h2>
            <Button
              type="button"
              className="hdr_btn"
              onClick={handleAddAddress}
            >
              <figure>
                <img src="/static/images/add_btn.svg" alt="" />
              </figure>
              Add
            </Button>
          </div>
          {allAddress?.length ? (
            <div className="saved_addrrss">
              <p style={{ padding: "20px" }}>Saved Addresses</p>
            </div>
          ) : null}
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
              className="addrss_sc"
            >
              {allAddress?.length ? (
                allAddress?.map((item) => (
                  <div
                    className="adrss_outr"
                    key={item?._id}
                    onClick={() => setSelectedId(item?._id)}
                  >
                    <div className="adrs_mn">
                      <div className="adrs_lft">
                        <FmdGoodIcon />
                        <div className="adrs_dtls">
                          <h4>
                            {item?.saveAs || ""}{" "}
                            {item?.default ? (
                              <span style={{ color: "#820000" }}>
                                (Default)
                              </span>
                            ) : null}
                          </h4>{" "}
                          <p>
                            {item?.address || ""} {item?.city || ""}
                          </p>
                          <p>{item?.landmark}</p>
                          <div className="edit_adrss">
                            <button
                              onClick={() => {
                                setShowAddAddress(true);
                                handleEditAddress(item?._id);
                              }}
                            >
                              Edit
                            </button>
                            <figure
                              onClick={() => {
                                setOpenModal(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <img src="/static/images/delete.png" alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <FormControlLabel
                        value={item?._id}
                        control={<Radio checked={item?._id === value} />}
                        label=""
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="noData" style={{ padding: "120px 0 20px 0" }}>
                  <figure>
                    <img src="/static/images/no_address.png" alt="" />
                  </figure>
                  <h3>No Address Found</h3>
                </div>
              )}
            </RadioGroup>
            {allAddress?.length ? (
              <Button onClick={handleDefaultAddress} className="sv_adrss">
                Save
              </Button>
            ) : null}
          </FormControl>
        </div>
      )}

      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Are you sure you want to delete this address?
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenModal(false);
                  handleDeleteAddress(selectedId);
                }}
              >
                Yes
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ManageAddresses;
