import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { Button, Pagination, ProductCard } from "../../components";
import cardData from "../../../src/data/ProductData.json";
import { useGetAllfavPropertyMutation } from "../../service/Product";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import {
  Category,
  CommonBody,
  FeaturedProductsData,
  RecentProduct,
  RecommendedProductsData,
  Subcategories,
} from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import {
  useGetAllRecentProductsMutation,
  useGetFeaturedProductsMutation,
  useGetRecommendedProductsMutation,
  useLazyGetCategoriesQuery,
  useLazyGetDashboardQuery,
} from "../../service/Home";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetSubCategoriesQuery } from "../../service/category";

function valuetext(value: number) {
  return `${value}°C`;
}

const SeeAll = () => {
  const name = useParams();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showFilters, setShowFilters] = useState(false);
  const [getFeaturedProducts, { isLoading }] = useGetFeaturedProductsMutation();
  const [getRecommendedProducts] = useGetRecommendedProductsMutation();
  const [getRecentProducts, GetRecentProductsData] =
    useGetAllRecentProductsMutation();
  const [getCategories, GetCategoriesData] = useLazyGetCategoriesQuery();
  const [subCategory, GetSubCategoryData] = useLazyGetSubCategoriesQuery();
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    []
  );

  const [favItems, setFavItems] = useState<any[]>([]);
  const [maxPrice, setMaxPrice] = useState<number>(1000000000);
  const [value, setValue] = useState<number[]>([1, maxPrice]);
  const [newSliderValue, setNewSliderValue] = useState<number[]>([]);

  const [featuredProducts, setFeaturedProducts] = useState<
    FeaturedProductsData[]
  >([]);

  const [recommendedProducts, setRecommendedProducts] = useState<
    RecommendedProductsData[]
  >([]);
  const [recentProducts, setRecentProducts] = useState<RecentProduct[]>([]);

  console.log(value, "value");
  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClearFilters = () => {
    window.location.reload();
    setSelectedValue("");
    setSelectedCategory(null);
    setSelectedSubCategories([]);
    setValue([1, maxPrice]);
    setNewSliderValue([]);
  };

  const handleSubCategoryChange = (subCategory: string) => {
    if (selectedSubCategories.includes(subCategory)) {
      setSelectedSubCategories((prevSelected) =>
        prevSelected.filter((item) => item !== subCategory)
      );
    } else {
      setSelectedSubCategories((prevSelected) => [
        ...prevSelected,
        subCategory,
      ]);
    }
  };
  const sortingOptiopns = [
    { value: "new", label: "New" },
    { value: "popular", label: "Popular" },
    { value: "lowToHigh", label: "Price Low To High" },
    { value: "highToLow", label: "Price High To Low" },
  ];
  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllFeaturedProducts = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: selectedSubCategories,
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getFeaturedProducts({
        body: encryptedBody,
        page: page,
        categoryId: selectedCategory ? selectedCategory?._id : "",
        sort: selectedValue,
        minPrice: value[0] || 1,
        maxPrice: value[1] || 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setFeaturedProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);

        if (!newSliderValue?.length) {
          setValue([1, response?.data?.maxPrice]);
        }
        setNewSliderValue([1, response?.data?.maxPrice || 10000]);

        // setValue([1, response?.data?.maxPrice]);
      } else {
        setFeaturedProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const getAllRecommendedProducts = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: selectedSubCategories,
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getRecommendedProducts({
        body: encryptedBody,
        page: page,
        categoryId: selectedCategory ? selectedCategory?._id : "",
        sort: selectedValue,
        minPrice: value[0] || 1,
        maxPrice: value[1] || 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecommendedProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        if (!newSliderValue?.length) {
          setValue([1, response?.data?.maxPrice]);
        }
        setNewSliderValue([1, response?.data?.maxPrice || 10000]);
        // setValue([1, response?.data?.maxPrice]);
      } else {
        setRecommendedProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllRecentProducts = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      subCategoryId: selectedSubCategories,
      deviceType: "WEB" as string,
      deviceToken: fcmtoken || "1234",
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await getRecentProducts({
        body: encryptedBody,
        page: page,
        categoryId: selectedCategory ? selectedCategory?._id : "",
        sort: selectedValue,
        minPrice: value[0] || 1,
        maxPrice: value[1] || 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setRecentProducts(response?.data?.products || []);
        setTotalCount(response?.data?.count || 0);
        setMaxPrice(response?.data?.maxPrice);
        if (!newSliderValue?.length) {
          setValue([1, response?.data?.maxPrice]);
        }
        setNewSliderValue([1, response?.data?.maxPrice || 10000]);
        // setValue([1, response?.data?.maxPrice]);
      } else {
        setRecentProducts([]);
        setTotalCount(0);
        setMaxPrice(0);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handleFilterClick = () => {
    if (name?.name === "featured") {
      getAllFeaturedProducts();
    } else {
      getAllRecommendedProducts();
    }
    // else {
    //   getAllRecentProducts();
    // }
  };
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    if (token || tempToken) {
      if (name?.name === "featured") {
        getAllFeaturedProducts();
      } else {
        getAllRecommendedProducts();
      }
      // else {
      //   getAllRecentProducts();
      // }
    }
  }, [tempToken, page]);

  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
    }
  }, [tempToken]);

  const handleCategoryClick = (item: Category) => {
    setSelectedCategory(item);
    setSelectedSubCategories([]);
    getSubCategory(item?._id);
  };

  const checkFavProperties = () => {
    if (name?.name === "featured") {
      const fav_data = featuredProducts
        .filter((item: any) => item?.isFav)
        .map((item: any) => item);
      setFavItems(fav_data);
    } else {
      const fav_data = recommendedProducts
        .filter((item: any) => item?.isFav)
        .map((item: any) => item);
      setFavItems(fav_data);
    }
    // else {
    //   const fav_data = recentProducts
    //     .filter((item: any) => item?.isFav)
    //     .map((item: any) => item);
    //   setFavItems(fav_data);
    // }
  };

  useEffect(() => {
    checkFavProperties();
  }, [featuredProducts, recommendedProducts, recentProducts]);

  return (
    <Layout>
      <Loader
        isLoad={
          isLoading ||
          GetSubCategoryData?.isLoading ||
          GetCategoriesData?.isLoading ||
          GetRecentProductsData?.isLoading
        }
      />
      <div className="wrapCls">
        <div className="container">
          <div className="filters">
            <div className="filters_dv">
              <h2>
                {name?.name === "featured"
                  ? "Featured Products"
                  : name?.name === "recommended"
                  ? "Recommended Products"
                  : "Recent Products"}
              </h2>
              <figure>
                <img
                  src="/static/images/filter_icn.svg"
                  onClick={() => setShowFilters(!showFilters)}
                />
              </figure>
            </div>
            <div className="sub_catMain">
              <div className={showFilters ? "sc_lft mob_dsply" : "sc_lft "}>
                <div className="sub">
                  <div className="filtr_top_hdng">
                    <h4>Categories</h4>
                    <p onClick={() => handleClearFilters()}>Clear All</p>
                  </div>
                  <ul>
                    {categories?.length
                      ? categories?.map((item) => {
                          return (
                            <li
                              key={item?._id}
                              onClick={() => handleCategoryClick(item)}
                              className={
                                selectedCategory === item ? "actv_cat" : ""
                              }
                            >
                              {item?.name || ""}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                </div>
                {selectedCategory &&
                selectedCategory?.subCateoryName?.length ? (
                  <div className="sub">
                    <h3>Sub-Categories</h3>
                    <ul>
                      {subCategories
                        .filter(
                          (subCategory) =>
                            subCategory?.parent === selectedCategory?._id
                        )
                        .map((subCategory) => (
                          <li key={subCategory?._id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedSubCategories.includes(
                                    subCategory?._id
                                  )}
                                  onChange={() =>
                                    handleSubCategoryChange(subCategory?._id)
                                  }
                                />
                              }
                              name={subCategory?.name}
                              label={subCategory?.name || ""}
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                ) : null}
                <div className="sub">
                  <h3>Sort By</h3>
                  <ul>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedValue}
                      onChange={handleSortingOptions}
                    >
                      {sortingOptiopns?.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  </ul>
                </div>

                <div className="sub">
                  <h3>Price</h3>
                  <ul style={{ paddingTop: 0 }}>
                    <li>
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        max={maxPrice}
                      />
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                          AED
                          {value[0].toLocaleString("en-IN")}
                        </span>
                        <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                          AED
                          {value[1].toLocaleString("en-IN")}
                        </span>
                      </div>

                      <div>
                        <Button
                          value="Apply Filter"
                          onClick={() => handleFilterClick()}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={showFilters ? "sc_Ryt hide_prdcts" : "sc_Ryt"}>
                <div className="prodcts_sc">
                  <div className="crds_sec">
                    {
                      name?.name === "featured" ? (
                        featuredProducts?.length ? (
                          featuredProducts.map((item) => (
                            <ProductCard
                              wishlist={item?.isFav}
                              key={item?._id}
                              product_id={item?._id}
                              product_image={item?.images[0]}
                              product_feature={item?.category?.name}
                              product_name={item?.name}
                              product_price={item?.price}
                              booster_applied={item?.boosterApplied}
                              location={item?.address}
                              setFavItems={setFavItems}
                              favItems={favItems}
                              item={item}
                            />
                          ))
                        ) : (
                          <div className="noData top_mt">
                            <figure>
                              <img src="/static/images/empty.png" alt="" />
                            </figure>
                            <h3>No Product Found</h3>
                          </div>
                        )
                      ) : recommendedProducts?.length ? (
                        recommendedProducts?.map((item) => (
                          <ProductCard
                            wishlist={item?.isFav}
                            key={item?._id}
                            product_id={item?._id}
                            booster_applied={item?.boosterApplied}
                            product_image={item?.images[0]}
                            product_feature={item?.category?.name}
                            product_name={item?.name}
                            product_price={item?.price}
                            location={item?.address}
                            setFavItems={setFavItems}
                            favItems={favItems}
                            item={item}
                          />
                        ))
                      ) : (
                        <div className="noData top_mt">
                          <figure>
                            <img src="/static/images/empty.png" alt="" />
                          </figure>
                          <h3>No Product Found</h3>
                        </div>
                      )

                      // : recentProducts?.length ? (
                      //   recentProducts?.map((item) => (
                      //     <ProductCard
                      //       wishlist={item?.isFav}
                      //       key={item?._id}
                      //       product_id={item?._id}
                      //       booster_applied={item?.boosterApplied}
                      //       product_image={item?.images[0]}
                      //       product_feature={item?.category?.name}
                      //       product_name={item?.name}
                      //       product_price={item?.price}
                      //       location={item?.address}
                      //       setFavItems={setFavItems}
                      //       favItems={favItems}
                      //       item={item}
                      //     />
                      //   ))
                      // ) : (
                      //   <div className="noData top_mt">
                      //     <figure>
                      //       <img src="/static/images/empty.png" alt="" />
                      //     </figure>
                      //     <h3>No Product Found</h3>
                      //   </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className={showFilters ? " hide_prdcts" : ""}>
              {featuredProducts?.length ||
              recommendedProducts?.length ||
              recentProducts?.length ? (
                <Pagination
                  module={
                    name.name == "featured"
                      ? featuredProducts
                      : name.name == "recommended"
                      ? recommendedProducts
                      : recentProducts
                  }
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeeAll;
