/* eslint-disable @typescript-eslint/ban-types */

import { END_POINTS } from "../helpers";
import { CommonBody, Faq, NotificationsResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  statusCode: number;
  message: string;
  data?: any;
};
type GetFaqResponse = {
  statusCode: number;
  data: {
    count: number;
    data: Faq[];
  };
  message: string;
};
type GetNotificationResponse = {
  statusCode: number;
  message: string;
  data: {
    notificationCount: number;
    pages: number;
    oldNotificationCount: number;
    olderNotifications: NotificationsResponse[];
    recentNotifications: NotificationsResponse[];
  };
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/ban-types
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/ban-types
    getFaq: builder.query<GetFaqResponse, {}>({
      query: () => ({
        url: END_POINTS.faq,
        method: "GET",
      }),
    }),
    postReview: builder.mutation<GetCmsResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.review,
        method: "POST",
        body,
      }),
    }),
    addReport: builder.mutation<
      GetCmsResponse,
      { id: string | undefined; encryptedBody: { message: string } }
    >({
      query: ({ id, encryptedBody }) => ({
        url: `${END_POINTS.report}/${id}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),
    getNotification: builder.query<GetNotificationResponse, { page: number }>({
      query: ({ page }) => ({
        url: `${END_POINTS.notification}?limit=${10}&page=${page}`,
        method: "GET",
      }),
    }),
    clearNotification: builder.query<any, {}>({
      query: ({}) => ({
        url: `${END_POINTS.notification}?clearAll=${true}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  useLazyGetFaqQuery,
  usePostReviewMutation,
  useAddReportMutation,
  useLazyGetNotificationQuery,
  useLazyClearNotificationQuery,
} = cmsApi;
