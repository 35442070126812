import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route
          path="/choose-category"
          element={
            <ProtectedRoutes>
              <Pages.ChooseCategory />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoutes>
              <Pages.Wishlist />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Pages.Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-product/:id"
          element={
            <ProtectedRoutes>
              <Pages.AddProduct />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/add-product/:id/:prodId"
          element={
            <ProtectedRoutes>
              <Pages.AddProduct />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoutes>
              <Pages.Chat />
            </ProtectedRoutes>
          }
        />
        <Route path="/products/:id" element={<Pages.Filters />} />
        <Route
          path="/notifications"
          element={
            <ProtectedRoutes>
              <Pages.Notifications />
            </ProtectedRoutes>
          }
        />
        <Route path="/product-detail/:id" element={<Pages.ProductDetail />} />
        <Route path="/all-products/:name" element={<Pages.SeeAll />} />
        <Route path="/contact-us" element={<Pages.ContactUs />} />
        <Route path="/about-us" element={<Pages.AboutUs />} />
        <Route path="/faqs" element={<Pages.Faqs />} />
        <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
        <Route
          path="/view-profile/:id"
          element={
            <ProtectedRoutes>
              <Pages.ViewProfile />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
};
export default Routing;
