import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components";
import { RecommendedProductsData } from "../../types/General";
import { useNavigate } from "react-router-dom";

type props = {
  recommendedProducts: RecommendedProductsData[];
};
const RecommendedProducts = ({ recommendedProducts }: props) => {
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = recommendedProducts
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [recommendedProducts]);

  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>Recommended Products</h2>
        {recommendedProducts?.length > 5 ? (
          <p onClick={() => navigate(`/all-products/${"recommended"}`)}>
            See All
          </p>
        ) : null}
      </div>

      <div className="crds_sec">
        {recommendedProducts?.length ? (
          recommendedProducts?.slice(0, 5)?.map((item) => {
            return (
              <ProductCard
                key={item?._id}
                product_id={item?._id}
                product_image={item?.images[0]}
                product_feature={item?.category?.name}
                product_name={item?.name}
                product_price={item?.price}
                location={item?.address}
                setFavItems={setFavItems}
                booster_applied={item?.boosterApplied}
                favItems={favItems}
                item={item}
              />
            );
          })
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/empty.png" alt="" />
            </figure>
            <h3>No Product Found</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default RecommendedProducts;
