/* eslint-disable jsx-a11y/role-supports-aria-props */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  SwipeableDrawer,
  Typography,
  Modal,
  Breadcrumbs,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchBar from "../../components/SearchBar";
import "../Layout.scss";
import Auth from "../../features/auth/Auth";
import {
  address,
  addressData,
  toggleAuthModal,
} from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets";
import useAuth from "../../hooks/useAuth";
import {
  useLazyGetProfileQuery,
  useLogoutApiMutation,
} from "../../service/Auth";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  successToast,
} from "../../helpers";
import {
  getTempToken,
  setCredentials,
  temporaryToken,
} from "../../reducers/authSlice";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  useGuestLoginMutation,
  useLazyGetCategoriesQuery,
  useLazyGetSearchHistoryQuery,
  useLazyGlobalSearchQuery,
  usePostClearSearchMutation,
  usePostSaveSearchHistoryMutation,
} from "../../service/Home";
import { isString } from "../../utils/validations";
import {
  AddressRes,
  CommonBody,
  ProductSearchData,
  RecommendedProductsData,
  globalSearch,
} from "../../types/General";
import { Category } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useLazyGetAddressQuery,
  usePostSelectAddressMutation,
} from "../../service/manageAddress";
import useAddress from "../../hooks/useAddress";
import { idText } from "typescript";

const Header = () => {
  const addresses = useAddress();
  const selectedAddress = addresses.find(
    (address) => address?.selectedAddress === true
  );
  console.log(selectedAddress, "selectedAddress");

  const user = useAuth();
  const pageLocation = window.location.pathname;
  const style = generateResponsiveStyle();
  const navigate = useNavigate();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const dispatch = useAppDispatch();
  const [path, setPath] = React.useState("");
  const [menu, setMenu] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [allAddress, setAllAddress] = useState<AddressRes[]>([]);
  // const [locationFilter, setLocationFilter] = useState<string | undefined>(
  //   selectedAddress !== undefined ? selectedAddress._id : user?.defaultAddressId
  // );
  const [locationFilter, setLocationFilter] = useState<string | undefined>(
    selectedAddress?.selectedAddress
      ? selectedAddress._id
      : user?.defaultAddressId
  );
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [getProfile, { isLoading }] = useLazyGetProfileQuery();
  const [postLogout, GetLogoutData] = useLogoutApiMutation();
  const [getCategories, GetCategoriesData] = useLazyGetCategoriesQuery();
  const [getAddress] = useLazyGetAddressQuery();
  const [searchHistoryData, setSearchHistoryData] = useState<
    ProductSearchData[]
  >([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchProduct, setSearchProduct] = useState<globalSearch[]>([]);
  const [guestLoginMutation] = useGuestLoginMutation();
  const [globalSearchMutation] = useLazyGlobalSearchQuery();
  const [saveSearchHistory] = usePostSaveSearchHistoryMutation();
  const [getSearchHistory] = useLazyGetSearchHistoryQuery();
  const [clearSearchHistory] = usePostClearSearchMutation();
  const [selectDefaultAddress] = usePostSelectAddressMutation();

  const handleDefaultAddress = async (value: string) => {
    try {
      const res = await selectDefaultAddress({
        id: value,
        isDefault: false,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(res?.message);
        getUserDetails();
        getAllAddress();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const formatRouteName = (route: string) => {
    // Replace hyphens with spaces and capitalize each word
    return route
      .replace(/-/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  useEffect(() => {
    if (pageLocation) {
      const route = pageLocation.replace("/", "");
      const name = route.split("/");
      let newName = "";
      if (name?.length > 1) {
        newName = formatRouteName(name.slice(0, -1).join(" "));
      } else {
        newName = formatRouteName(route);
      }
      setPath(newName);
    }
  }, [pageLocation]);

  const getAllAddress = async () => {
    try {
      const response = await getAddress({}).unwrap();
      if (response?.statusCode === 200) {
        setAllAddress(response?.data?.address || []);
        dispatch(
          addressData({
            address: response?.data?.address,
          })
        );
      } else {
        setAllAddress([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const guestLogin = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      deviceType: "WEB",
      deviceToken: fcmtoken || "1234",
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await guestLoginMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          temporaryToken({
            tempToken: response?.data?.token || null,
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (!token) {
      guestLogin();
    }
  }, []);

  const getSearchHistoryData = async () => {
    try {
      const response = await getSearchHistory({}).unwrap();
      if (response?.statusCode === 200) {
        setShowSearch(true);
        setSearchHistoryData(response?.data?.productSearch || []);
      } else {
        setSearchHistoryData([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const clearSearchHistoryData = async () => {
    try {
      const response = await clearSearchHistory({}).unwrap();
      if (response?.statusCode === 200) {
        getSearchHistoryData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const closeModal = () => {
    setOpenLogout(false);
  };
  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleLogout = async () => {
    try {
      const response = await postLogout({}).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: null,
            user: null,
          })
        );
        dispatch(
          temporaryToken({
            tempToken: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);
        removeFromStorage(STORAGE_KEYS.tempToken);

        closeModal();
        navigate("/");
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };
  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const globalSearch = async () => {
    try {
      const res = await globalSearchMutation({
        search: debouncedSearchTerm.trim(),
      }).unwrap();

      if (res?.statusCode === 200) {
        setShowSearch(true);
        setSearchProduct(res?.data?.ItemSearch?.productSearch || null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveSearchHistory = async (id: string) => {
    let data = {
      type: 1,
      search: searchTerm,
      objectId: id,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await saveSearchHistory(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        setDebouncedSearchTerm("");
        setSearchTerm("");
        setSearchProduct([]);
        navigate(`/product-detail/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token || tempToken) {
      globalSearch();
    }
  }, [debouncedSearchTerm, tempToken]);

  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
      getAllAddress();
    }
  }, [tempToken]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarVisible(open);
    };

  // const handleLocationChange = (event: SelectChangeEvent) => {
  //   setLocationFilter(event.target.value as string);
  // };
  const handleLocationChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setLocationFilter(selectedValue);
    handleDefaultAddress(selectedValue); // Call handleDefaultAddress when the value changes
  };

  const handleMenu = (event: SelectChangeEvent) => {
    setMenu(event.target.value as string);
  };

  const handleLogin = () => {
    if (user) {
      navigate("/choose-category");
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl1(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const handleLocationClick = () => {
    if (user) {
      navigate("/profile", { state: "location" });
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };
  useMemo(() => {
    if (selectedAddress?.selectedAddress) {
      setLocationFilter(selectedAddress._id);
    } else {
      setLocationFilter(user?.defaultAddressId || "");
    }
  }, [user, selectedAddress]);

  // const list = () => (
  //   <Box sx={{ width: 300 }} role="presentation">
  //     <div className="hdr_mnu">
  //       <div className="itemlogo">
  //         <div onClick={() => navigate("/")} className="logo">
  //           <h2>LOGO</h2>
  //           {/* <figure>
  //             <img src="/static/images/logo.png" alt="logo" />
  //           </figure> */}
  //         </div>
  //         <CloseIcon onClick={() => setSidebarVisible(false)} />
  //       </div>
  //       <FormControl className="loctn_fltr">
  //         <Select
  //           className="select_div"
  //           // fullWidth
  //           labelId="demo-simple-select-label"
  //           id="demo-simple-select"
  //           value={locationFilter}
  //           inputProps={{
  //             "aria-label": "Without label",
  //           }}
  //           onChange={handleLocationChange}
  //           displayEmpty
  //         >
  //           <MenuItem value="" disabled>
  //             Select Location
  //           </MenuItem>
  //           {addresses?.map((item) => (
  //             <MenuItem key={item._id} value={item?.address}>
  //               {item?.address}
  //             </MenuItem>
  //           ))}
  //           {/* <MenuItem value="Lower Manhattan">Lower Manhattan</MenuItem>
  //           <MenuItem value="Lower Manhattan">Lower Manhattan</MenuItem>
  //           <MenuItem value="Lower Manhattan">Lower Manhattan</MenuItem> */}
  //         </Select>
  //       </FormControl>
  //       <div className="hdr_ryt">
  //         <Button
  //           type="button"
  //           className="hdr_btn"
  //           // onClick={() => navigate("/choose-category")}
  //           onClick={() => handleLogin()}
  //         >
  //           <figure>
  //             <img src="/static/images/add_btn.svg" alt="" />
  //           </figure>
  //           Sell
  //         </Button>
  //       </div>
  //       {/* <Accordion>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <Typography>All Categories</Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <ul>
  //             {categoriesData?.map((category, index) => (
  //               <li key={index}>
  //                 <Subcategories
  //                   subcategory={category?.subcategory}
  //                   subSubcategories={category?.subSubcategories}
  //                 />
  //               </li>
  //             ))}
  //           </ul>
  //         </AccordionDetails>
  //       </Accordion> */}
  //     </div>
  //   </Box>
  // );

  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  return (
    <header className="hdr">
      <Loader isLoad={GetLogoutData?.isLoading} />
      <div className="hdr_mn">
        <div className="container hdr_top">
          <div className="hdr_lft">
            <button className="burger" type="button">
              <div className="burger-block" onClick={toggleDrawer(true)}>
                <span />
                <span />
                <span />
              </div>
            </button>
            <figure
              className="logo_img"
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            >
              <img src="/static/images/logo.png" />
            </figure>
            {/* <h2 onClick={() => navigate("/")}>KRAFO</h2> */}
            {user && addresses?.length ? (
              <FormControl className="loctn_fltr">
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={locationFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  onChange={handleLocationChange}
                  // displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Location
                  </MenuItem>
                  {addresses?.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.address}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <figure className="location_icnnn" onClick={handleLocationClick}>
                <img src="/static/images/location_icn.svg" />
              </figure>
            )}
          </div>
          <div className="hdr_srch">
            <SearchBar
              onCross={() => setSearchTerm("")}
              placeholder="Search for Cars, Mobile & more…."
              value={searchTerm}
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isString(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              onClick={() => {
                getSearchHistoryData();
              }}
            />

            {showSearch &&
            (searchProduct?.length ||
              debouncedSearchTerm?.length ||
              searchHistoryData?.length) ? (
              <div className="product_srch">
                <div className="srch_hdng">
                  <h4>Recently Searched</h4>
                  {!debouncedSearchTerm?.length ? (
                    <p
                      className="clr_btn"
                      onClick={() => clearSearchHistoryData()}
                    >
                      Clear
                    </p>
                  ) : null}
                </div>
                {debouncedSearchTerm?.length ? (
                  searchProduct?.length ? (
                    searchProduct?.map((item) => {
                      return (
                        <>
                          <ul key={item?.id}>
                            <li
                              className="main_li"
                              onClick={() => {
                                handleSaveSearchHistory(item?._id);
                              }}
                            >
                              <div className="li_left">
                                {item?.images?.length
                                  ? item?.images
                                      ?.slice(0, 1)
                                      ?.map((val: any, i: number) => {
                                        return (
                                          <figure key={i}>
                                            <img src={val} alt="Product" />
                                          </figure>
                                        );
                                      })
                                  : ""}
                              </div>
                              <div className="li_ryt">
                                <div className="stock_flex">
                                  <h3>{item?.name || ""}</h3>
                                </div>
                                <p>{item?.categoryId?.name || ""}</p>
                              </div>
                            </li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <h2 className="no_record">No Product Found</h2>
                  )
                ) : searchHistoryData?.length ? (
                  searchHistoryData?.map((item) => {
                    return (
                      <>
                        <ul key={item?.additionalInfo?.categoryId?._id}>
                          <li
                            className="main_li"
                            onClick={() => {
                              navigate(`/product-detail/${item?.objectId}`, {
                                state: "saveSearchHistory",
                              });
                              setShowSearch(false);
                            }}
                          >
                            <div className="li_left">
                              {item?.additionalInfo?.images?.length
                                ? item?.additionalInfo?.images
                                    ?.slice(0, 1)
                                    ?.map((val: any, i: number) => {
                                      return (
                                        <figure key={i}>
                                          <img src={val} alt="Product" />
                                        </figure>
                                      );
                                    })
                                : ""}
                            </div>
                            <div className="li_ryt">
                              <div className="stock_flex">
                                <h3>{item?.additionalInfo?.name || ""}</h3>
                              </div>
                              <p>
                                {item?.additionalInfo?.categoryId?.name || ""}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </>
                    );
                  })
                ) : (
                  <h2 className="no_record">No Product Found</h2>
                )}
              </div>
            ) : null}
          </div>
          <div className="hdr_ryt">
            {user ? (
              <Box className="users_dv">
                <IconButton
                  className="topbar_user_btn"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img
                    className={user?.image ? "img_style" : "img_style2"}
                    src={user?.image || "/static/images/empty_user.png"}
                    alt=""
                  />

                  <KeyboardArrowDownIcon />
                </IconButton>
                <Menu
                  className="userDropdown_menu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 10,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    style={{ color: Colors.secondary }}
                    value="Profile"
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </MenuItem>

                  <MenuItem
                    style={{ color: Colors.secondary }}
                    value="Chat"
                    onClick={() => navigate("/chat")}
                  >
                    Chat
                  </MenuItem>
                  <MenuItem
                    style={{ color: Colors.secondary }}
                    value="wishlist"
                    onClick={() => navigate("/wishlist")}
                  >
                    Wishlist
                  </MenuItem>
                  <MenuItem
                    style={{ color: Colors.secondary }}
                    value="notifications"
                    onClick={() => navigate("/notifications")}
                  >
                    Notifications
                  </MenuItem>
                  {user?.isEmailVerify ? (
                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="Change Password"
                      onClick={() => {
                        handleClose();
                        dispatch(
                          toggleAuthModal({
                            isAuthModalVisible: true,
                            ModalType: "changePassword",
                          })
                        );
                      }}
                    >
                      Change Password
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    style={{ color: Colors.secondary }}
                    value="Logout"
                    onClick={() => {
                      handleClose();
                      setOpenLogout(true);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <h4 onClick={() => handleLogin()}>Login</h4>
            )}

            <Button
              type="button"
              className="hdr_btn desk_btn"
              onClick={() => handleLogin()}
            >
              <figure>
                <img src="/static/images/add_btn.svg" alt="" />
              </figure>
              Sell
            </Button>
            <Button
              type="button"
              className="hdr_btn mob_btn"
              // onClick={() => navigate("/choose-category")}
              onClick={() => handleLogin()}
            >
              <figure>
                <img src="/static/images/add_btn.svg" alt="" />
              </figure>
            </Button>

            <figure className="bell_mob">
              <img src="/static/images/location_icn.svg" />
            </figure>
          </div>
        </div>
      </div>
      <div className="btm_hd">
        <div className="srch_hdr">
          <SearchBar
            onCross={() => setSearchTerm("")}
            placeholder="Search for Cars, Mobile & more…."
            value={searchTerm}
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            onChange={(val: any) => {
              if (isString(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
            onClick={() => {
              getSearchHistoryData();
            }}
          />

          {showSearch &&
          (searchProduct?.length ||
            debouncedSearchTerm?.length ||
            searchHistoryData?.length) ? (
            <div className="product_srch">
              <div className="srch_hdng">
                <h4>Recently Searched</h4>
                {!debouncedSearchTerm?.length ? (
                  <p
                    className="clr_btn"
                    onClick={() => clearSearchHistoryData()}
                  >
                    Clear
                  </p>
                ) : null}
              </div>
              {debouncedSearchTerm?.length ? (
                searchProduct?.length ? (
                  searchProduct?.map((item) => {
                    return (
                      <>
                        <ul key={item?.id}>
                          <li
                            className="main_li"
                            onClick={() => {
                              handleSaveSearchHistory(item?._id);
                            }}
                          >
                            <div className="li_left">
                              {item?.images?.length
                                ? item?.images
                                    ?.slice(0, 1)
                                    ?.map((val: any, i: number) => {
                                      return (
                                        <figure key={i}>
                                          <img src={val} alt="Product" />
                                        </figure>
                                      );
                                    })
                                : ""}
                            </div>
                            <div className="li_ryt">
                              <div className="stock_flex">
                                <h3>{item?.name || ""}</h3>
                              </div>
                              <p>{item?.categoryId?.name || ""}</p>
                            </div>
                          </li>
                        </ul>
                      </>
                    );
                  })
                ) : (
                  <h2 className="no_record">No Product Found</h2>
                )
              ) : searchHistoryData?.length ? (
                searchHistoryData?.map((item) => {
                  return (
                    <>
                      <ul key={item?.additionalInfo?.categoryId?._id}>
                        <li
                          className="main_li"
                          onClick={() => {
                            navigate(`/product-detail/${item?.objectId}`, {
                              state: "saveSearchHistory",
                            });
                            setShowSearch(false);
                          }}
                        >
                          <div className="li_left">
                            {item?.additionalInfo?.images?.length
                              ? item?.additionalInfo?.images
                                  ?.slice(0, 1)
                                  ?.map((val: any, i: number) => {
                                    return (
                                      <figure key={i}>
                                        <img src={val} alt="Product" />
                                      </figure>
                                    );
                                  })
                              : ""}
                          </div>
                          <div className="li_ryt">
                            <div className="stock_flex">
                              <h3>{item?.additionalInfo?.name || ""}</h3>
                            </div>
                            <p>
                              {item?.additionalInfo?.categoryId?.name || ""}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </>
                  );
                })
              ) : (
                <h2 className="no_record">No Product Found</h2>
              )}
            </div>
          ) : null}
        </div>
        <ul className="container hdr_btm">
          <li
            className="notification_btn"
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <h2>
              All Categories <KeyboardArrowDownIcon />
            </h2>
          </li>
          {categories?.length
            ? categories?.slice(0, 7)?.map((item, i) => {
                return (
                  <li key={item?._id}>
                    <h4
                      onClick={() =>
                        navigate(`/products/${item?._id}`, {
                          state: item?.name,
                        })
                      }
                    >
                      {item?.name || ""}
                    </h4>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <Auth />

      <Menu
        className="notiDropdown_menu"
        id="basic-menu1"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        MenuListProps={{
          "aria-labelledby": "basic-button1",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            top: 110,
            left: 97,
            borderRadius: "0px 0px 20px 20px",

            width: "90%",
            "& .MuiAvatar-root": {},
          },
        }}
      >
        <ul>
          {categories?.length
            ? categories?.map((item, i) => {
                return (
                  <li key={item?._id}>
                    <h3
                      onClick={() => {
                        navigate(`/products/${item?._id}`, {
                          state: item?.name,
                        });
                        setAnchorEl1(null);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item?.name || ""}
                    </h3>
                    <div className="sub_cat">
                      {item?.subCateoryName?.length
                        ? item?.subCateoryName?.map((val, i) => {
                            return <p key={i}>{val || ""}</p>;
                          })
                        : null}
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
      </Menu>
      <Modal
        open={openLogout}
        onClose={closeModal}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenLogout(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              Are you sure you want to logout?
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => {
                  setOpenLogout(false);
                  handleLogout();
                }}
              >
                Yes
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenLogout(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      <div className={pageLocation === "/" ? "no_brdcrmbs" : "mn_brdcrmbs"}>
        <div className="container">
          <div
            role="presentation"
            onClick={(e) => handleBreadCrumbClick(e)}
            style={{
              padding: "30px 0",

              zIndex: 1,
              cursor: "pointer",
            }}
          >
            <Breadcrumbs
              key="1"
              aria-label="breadcrumb"
              separator={path === "" ? "" : "›"}
            >
              <div
                onClick={() => {
                  navigate("/");
                }}
                className={"brdcrmb"}
              >
                Home
              </div>

              <Typography
                key="2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#820000",
                  textTransform: "capitalize",
                }}
                color="text.primary"
              >
                {path}
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
