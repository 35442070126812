import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { ProductBannerData } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";

type props = {
  productBannerData: ProductBannerData[];
};

const Banners = ({ productBannerData }: props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAuth();
  // const handleClick = () => {
  //   if (user) {
  //     navigate("/choose-category");
  //   } else {
  //     dispatch(
  //       toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
  //     );
  //   }
  // };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    // speed: 2000,
    autoplaySpeed: 3000,
  };
  return (
    <section className=" container">
      {productBannerData?.length ? (
        <Slider {...settings}>
          {productBannerData?.map((item) => {
            return (
              <div
                className="hm_bnr"
                key={item?._id}
                style={{
                  backgroundImage: `url("/static/images/app_store.png")`,
                }}
              >
                <div
                  className="bnr"
                  style={{
                    backgroundImage: item?.webImage
                      ? `url(${item?.webImage})`
                      : `url("/static/images/facebook.png")`,
                  }}
                >
                  <div className="mn_bnr">
                    <span />
                    <div className="bnr_lft">
                      <h1>{item?.title || ""}</h1>

                      <p>{item?.feature || ""}</p>
                      <button
                        onClick={() =>
                          navigate(`/products/${item?.category?._id}`, {
                            state: item?.category?.name,
                          })
                        }
                      >
                        Explore more
                      </button>
                    </div>
                    {/* <div className="bnr_ryt">
                    <figure>
                      <img src={item?.webImage || ""} alt="" />
                    </figure>
                  </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : null}
    </section>
  );
};

export default Banners;
