import React, { useEffect, useState } from "react";
import { useLazyGetFollowListQuery } from "../../service/Product";
import useAuth from "../../hooks/useAuth";
import { errorToast } from "../../helpers";
import { FollowList } from "../../types/General";

const TotalFollowers = () => {
  const user = useAuth();
  const [followers, setFollowers] = useState<FollowList[]>([]);
  const [getFollowers, { isLoading }] = useLazyGetFollowListQuery();

  const fetchData = async () => {
    try {
      const response = await getFollowers({
        userId: user?._id,
        type: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setFollowers(response?.data?.followers || []);
      } else {
        setFollowers([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main">
      <h2>Total Followers</h2>
      <ul className="followers_lst">
        {followers?.length ? (
          followers?.map((item) => {
            return (
              <li className="lstt" key={item?._id}>
                <figure className="delete_dv">
                  <img
                    src={
                      item?.followedBy?.image ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
                <h4>{item?.followedBy?.fullName || ""}</h4>
              </li>
            );
          })
        ) : (
          <div className="no_followers_found">
            <p>No followers found</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default TotalFollowers;
