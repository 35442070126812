import React, { Dispatch, SetStateAction } from "react";

import "./Components.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";

type ProductType = {
  product_id: string;
  product_image: string;
  product_feature: string;
  product_name: string;
  product_price: number;
  location: string;
  wishlist?: boolean;
  category_id: string;
  listing_date?: string;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedId: Dispatch<SetStateAction<string>>;
  booster_applied: boolean;
};

const ProductListingCard = ({
  product_id,
  product_image,
  product_feature,
  product_name,
  product_price,
  location,
  wishlist,
  listing_date,
  category_id,
  setOpenModal,
  setSelectedId,
  booster_applied,
}: ProductType) => {
  const navigate = useNavigate();

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpenModal(true);
    setSelectedId(product_id);
  };
  return (
    <div
      className="prdct_listing_crd"
      onClick={() =>
        navigate(`/product-detail/${product_id}`, {
          state: { page: "my_listing" },
        })
      }
    >
      <figure className="right_arrw">
        <img src="/static/images/right_arrow.svg" alt="" />
      </figure>
      <div className="lst_crd_lft">
        <figure>
          <img src={product_image} alt="" />
        </figure>
        <div className="lst_dtl">
          <h6>
            {product_feature || ""}{" "}
            <figure>
              {booster_applied ? (
                <img src="/static/images/booster.png" alt="" />
              ) : null}
            </figure>
          </h6>
          <h4>
            {" "}
            {product_name?.length > 35
              ? product_name.slice(0, 35) + "...."
              : product_name || ""}
          </h4>
          <p>{moment(listing_date).format("ll")}</p>
          <h3>AED {product_price.toLocaleString("en-IN") || ""}</h3>
        </div>
      </div>
      <div className="lst_crd_ryt">
        {/* <figure>
          <img src="/static/images/empty.png" />
        </figure> */}
        <figure onClick={(e: any) => handleOpenModal(e)}>
          <img src="/static/images/delete.png" alt="" />
        </figure>
      </div>
    </div>
  );
};

export default ProductListingCard;
