import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetCategoriesQuery,
  usePostSubscribeNewsLetterMutation,
} from "../../service/Home";
import { Category, CommonBody } from "../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const Footer = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[]>([]);
  const [getCategories, GetCategoriesData] = useLazyGetCategoriesQuery();
  const [subscribeNewLetter, { isLoading }] =
    usePostSubscribeNewsLetterMutation();

  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      // const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      let data = {
        email: formik.values.email,
        // deviceType: "WEB",
        // deviceToken: fcmtoken || "1234",
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await subscribeNewLetter(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
    }
  }, [tempToken]);

  return (
    <footer className="ftr">
      <Loader isLoad={isLoading} />
      <div className="ftr_top">
        <ul className="container mn_ftr">
          <li>
            {/* <h2>KRAFO</h2> */}
            <figure className="logo_img">
              <img src="/static/images/logo.png" />
            </figure>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </li>
          <li>
            <h4>Categories</h4>
            {categories?.length
              ? categories?.slice(0, 7)?.map((item) => {
                  return (
                    <h6
                      key={item?._id}
                      onClick={() =>
                        navigate(`/products/${item?._id}`, {
                          state: item?.name,
                        })
                      }
                    >
                      {item?.name || ""}
                    </h6>
                  );
                })
              : null}{" "}
          </li>
          <li>
            {/* <h4 style={{ color: "#1d1d1d" }}>Categories</h4> */}
            {categories?.length
              ? categories?.slice(7, categories?.length)?.map((item) => {
                  return (
                    <h6
                      key={item?._id}
                      onClick={() =>
                        navigate(`/products/${item?._id}`, {
                          state: item?.name,
                        })
                      }
                    >
                      {item?.name || ""}
                    </h6>
                  );
                })
              : null}{" "}
          </li>
          <li>
            <h4>Support</h4>
            <h6 onClick={() => navigate("/contact-us")}>Contact Us</h6>
            <h6 onClick={() => navigate("/faqs")}>FAQ</h6>
            <h6 onClick={() => navigate("/about-us")}>About Us</h6>
            <h6 onClick={() => navigate("/privacy-policy")}>Privacy Policy</h6>
          </li>
          <li>
            <h4>Follow Us</h4>
            <div className="scl_icns">
              <a
                href="https://www.facebook.com/profile.php?id=61554321963962"
                target="_blank"
                rel="noopener noreferrer"
              >
                <figure>
                  <img src="/static/images/facebook.png" />
                </figure>
              </a>
              <figure>
                <img src="/static/images/instagram.png" />
              </figure>
              <figure>
                <img src="/static/images/linkedin.png" />
              </figure>
            </div>
            <div className="subscribe_ftr">
              <h4>Subscribe</h4>
              <form onSubmit={formik.handleSubmit}>
                <div className="sub_btn">
                  <TextField
                    name="email"
                    id="email"
                    className="ftr_txtfld "
                    hiddenLabel
                    placeholder="Enter your email"
                    fullWidth
                    variant="outlined"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <button type="submit">Subscribe</button>
                </div>
              </form>
            </div>
          </li>
        </ul>
      </div>
      <div className="ftr_btm">
        <p>© 2023 Apptunix. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
