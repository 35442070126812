// @ts-nocheck

import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import AddIcon from "@mui/icons-material/Add";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Button, InputField } from "../../components";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import "./addproduct.scss";
import LogoHeader from "../../layout/logoHeader";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isString } from "../../utils/validations";
import moment from "moment";
import {
  CommonBody,
  Subcategories,
  boosterPlans,
  globalSearch,
} from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useAddBoosterMutation,
  useAddProductMutation,
  useLazyGetBoosterQuery,
  useLazyGetMyProductByIdQuery,
  useUpdateProductMutation,
} from "../../service/Product";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";
import { useLazyGetSubCategoriesQuery } from "../../service/category";
import { UploadMedia } from "../../utils/uploadMedia";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import useAuth from "../../hooks/useAuth";
import { Colors } from "../../assets";

const AddProduct = () => {
  const userData = useAuth();
  const style = generateResponsiveStyle();
  const { id } = useParams();
  const { prodId } = useParams();
  const [updateProduct, updateProductData] = useUpdateProductMutation();
  const navigate = useNavigate();
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [getBoosterMutation] = useLazyGetBoosterQuery();
  const [addBoosterMutation] = useAddBoosterMutation();
  const [open, setOpen] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [productById] = useLazyGetMyProductByIdQuery();
  const [manufacturingDate, setManufacturingDate] = useState<Date | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [myImages, setMyImages] = useState<any[]>([]);
  const [subCat, setSubCat] = useState<string>("");
  const [booseterCheck, setBoosterCheck] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [makeOffer, setMakeOffer] = useState(true);
  const [subCategory, GetSubCategoryData] = useLazyGetSubCategoriesQuery();
  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);
  const [booster, setBooster] = useState<boosterPlans[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [error, setError] = useState(false);
  const [getPropertyData, setGetPropertyData] = useState<globalSearch>();
  const [brand, setBrand] = useState<string>("");
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<Subcategories | null>(null);

  const handleBrandChange = (event: SelectChangeEvent) => {
    setBrand(event.target.value as string);
  };
  console.log(selectedValue, "selectedValue");

  // Generate initial values and validation schema dynamically
  const generateInitialValuesAndSchema = () => {
    const initialValues = {};
    const validationSchemaFields = {};

    if (prodId) {
      for (
        let index = 0;
        index < getPropertyData?.dynamicFields.length;
        index++
      ) {
        // const field = selectedSubcategory?.dynamicFields[index];
        const field2 = getPropertyData?.dynamicFields[index];
        console.log("field: called", field2);
        const fieldName = Object.keys(field2)[0];
        const fieldValues = Object.values(field2)[0];
        // const fieldType = field[fieldName];
        initialValues[fieldName] = fieldValues || "";
        validationSchemaFields[fieldName] = Yup.string().required(
          `This field is required.`
        );
      }
    } else {
      for (
        let index = 1;
        index < selectedSubcategory?.dynamicFields.length;
        index++
      ) {
        const field = selectedSubcategory?.dynamicFields[index];

        console.log("field: called", field);
        const fieldName = Object.keys(field)[0];
        // const fieldValues = Object.values(field)[0];
        // const fieldType = field[fieldName];
        initialValues[fieldName] = "";
        validationSchemaFields[fieldName] = Yup.string().required(
          `This field is required.`
        );
      }
    }

    return {
      initialValues,
      validationSchema: Yup.object(validationSchemaFields),
    };
  };

  const { initialValues, validationSchema } = generateInitialValuesAndSchema();

  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
  };

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      productName: getPropertyData?.name || "",
      price: getPropertyData?.price || "",
      manufacDate: "",
      location: getPropertyData?.address || "",
      description: getPropertyData?.description || "",
    },

    validationSchema: validationSchema.shape({
      productName: Yup.string()
        .required("This field is required.")
        .min(3, "Minimum 3 characters are required"),
      price: Yup.string()
        .required("This field is required.")
        .min(2, "Minimum 2 characters are required"),
      location: Yup.string()
        .required("This field is required.")
        .min(3, "Minimum 3 characters are required"),
      description: Yup.string()
        .required("This field is required.")
        .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      // let images: string[] = [];
      // if (myImages?.length) {
      //   setLoading(true);

      //   await Promise.all(
      //     myImages?.map(async (item: any) => {
      //       if (typeof item?.file === "string") {
      //         images?.push(item?.url);
      //         return;
      //       } else {
      //         const res = await UploadMedia(item?.file);
      //         if (res?.statusCode === 200 && res?.data?.length) {
      //           images?.push(res?.data);
      //         }
      //       }
      //     })
      //   );
      // }

      // if (!subCat) {
      //   setError(true);
      //   return;
      // }
      // if (!images?.length) {
      //   errorToast("Images are required");
      //   return;
      // }
      // setError(false);
      // setLoading(false);

      handleAddProduct();

      formik.setSubmitting(false);
    },
  });

  const paymentFormik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required("This field is required")

        .min(3, "Card holder name must be at least 3 characters")
        .max(20),
      cardNumber: Yup.string().required("This field is required").min(16),
      expiryDate: Yup.string().required("This field is required"),
      cvv: Yup.string().required("This field is required").min(3),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setOpen(false);
      formik.resetForm();
    },
  });

  const handleAddProduct = async () => {
    let images: string[] = [];
    if (myImages?.length) {
      setLoading(true);

      await Promise.all(
        myImages?.map(async (item: any) => {
          if (typeof item?.file === "string") {
            images?.push(item?.url);
            return;
          } else {
            const res = await UploadMedia(item?.file);
            if (res?.statusCode === 200 && res?.data?.length) {
              images?.push(res?.data);
            }
          }
        })
      );
    }
    if (!subCat) {
      setError(true);
      return;
    }
    if (!images?.length) {
      errorToast("Images are required");
      return;
    }
    setError(false);
    setLoading(false);

    const dynamicFieldsArraay = selectedSubcategory?.dynamicFields
      .slice(1) // Skip the first dynamic field
      .map((field) => {
        const fieldName = Object.keys(field)[0];
        return {
          name: fieldName,
          value: formik.values[fieldName],
        };
      });

    const transformedArray = dynamicFieldsArraay?.map(({ name, value }) => ({
      [name]: value,
    }));
    const data = {
      name: formik.values.productName,
      description: formik.values.description,
      price: formik.values.price,
      makeAnOffer: makeOffer,
      images: images,
      address: formik.values.location,
      categoryId: id || "",
      subCategoryId: subCat || "",
      dynamicFields: transformedArray,
      brand: brand || "",
    };
    console.log(data, "data");

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      if (selectedValue) {
        setOpen(true);
        return;
      }
      if (!prodId) {
        if (booseterCheck) {
          buyBooster(selectedValue);
        }
        const res = await addProduct(encryptedBody).unwrap();
        if (res?.statusCode === 200) {
          if (res?.data) {
            successToast("Product Added Successfully");

            formik.resetForm();
            setMyImages([]);
            setSubCat("");
            setBrand("");
            setError(false);
            navigate("/profile", { state: "listing" });
          }
        }
      } else {
        const res = await updateProduct({
          id: prodId,
          encryptedBody,
        }).unwrap();
        if (res?.statusCode === 200) {
          if (res?.data) {
            successToast("Product Updated Successfully");
            formik.resetForm();
            setMyImages([]);
            setSubCat("");
            setBrand("");
            setError(false);
            navigate("/profile", { state: "listing" });
          }
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyImages([...myImages, ...uploadedImages]);
    }
  };

  const handleCrossClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name) => name !== i);
      setMyImages(arr);
    }
  };

  const Input = styled("input")({
    // display: "none",
  });

  const handleSubCategoryChange = (event: SelectChangeEvent) => {
    setSubCat(event.target.value as string);

    // Find the selected subcategory from the subCategories array
    const selectedSubcategory = subCategories.find(
      (item: Subcategories) => item?._id === event.target.value
    );

    // Update the selectedSubcategory state
    setSelectedSubcategory(selectedSubcategory || null);
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getProductById = async (id: string) => {
    try {
      const res = await productById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setGetPropertyData(res?.data);
        setMakeOffer(res?.data?.makeAnOffer);
        setSubCat(res?.data?.subCategoryId || "");
        setBrand(res?.data?.brand || "");
        // setSelectedSubcategory(res?.data?)
        // Update selected subcategory based on the received subcategory ID
        if (subCategories) {
          setSelectedSubcategory(
            subCategories.find(
              (item: Subcategories) => item?._id === res?.data?.subCategoryId
            )
          );
        }
        const transSimpleArray = res?.data?.dynamicFields?.map((obj) => {
          const [name] = Object.keys(obj);
          const value = obj[name];
          return { name, value };
        });

        console.log(transSimpleArray, "transSimpleArray");

        // Set dynamicFields values in Formik
        if (transSimpleArray && transSimpleArray.length > 0) {
          const dynamicFieldsValues = {};
          transSimpleArray.forEach(({ name, value }) => {
            dynamicFieldsValues[name] = value;
            console.log(transSimpleArray, "transSimpleArray22");
          });

          formik.setValues((prevValues) => ({
            ...prevValues,
            ...dynamicFieldsValues,
          }));
        }

        if (res?.data?.images?.length) {
          if (res?.data?.images?.length && myImages?.length === 0) {
            let newObj = res?.data?.images?.map((item) => {
              return {
                file: "File" as any,
                url: item as string,
              };
            });

            setMyImages([...myImages, ...newObj]);
          }
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getBooster = async () => {
    try {
      const result = await getBoosterMutation({}).unwrap();
      if (result?.statusCode === 200) {
        setBooster(result?.data?.boosterPlans || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Create a function to handle value change
  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and format as a number
    const newValue = parseFloat(inputValue.replace(/[^\d.]/g, ""));
    // Check if the numericValue is NaN or a valid number
    if (!isNaN(newValue)) {
      formik.setFieldValue("price", newValue);
    } else {
      formik.setFieldValue("price", "");
    }
  };

  const formatNumberWithCommas = (number: any) => {
    if (number > 0) {
      const formattedNumber = number.toLocaleString("en-IN");
      return `${formattedNumber}`;
    }
    return "";
  };

  const buyBooster = async (id: string) => {
    try {
      const result = await addBoosterMutation(id).unwrap();
      if (result?.statusCode === 200) {
        setOpen(false);
        successToast(result?.message || "");
        handleAddProduct();

        // setBooster(result?.data?.boosterPlans || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (prodId) {
      getProductById(prodId);
    }
  }, [prodId, subCategories]);

  useEffect(() => {
    if (id) {
      setSelectedSubcategory(null);
      getSubCategory(id);
    }
  }, [id]);

  useEffect(() => {
    getBooster();
  }, []);

  return (
    <>
      <Loader isLoad={isLoading || loading || updateProductData?.isLoading} />
      <LogoHeader />
      <div className="wrapCls">
        <div className="container">
          <div className="addProduct">
            <h2>You’re almost there!</h2>
            <h5>
              Include as much details and pictures as possible, and set the
              right price!
            </h5>
            <ul>
              <label htmlFor="icon-button-files">
                <div className="uploader">
                  <Input
                    style={{ display: "none" }}
                    id="icon-button-files"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileUpload}
                  />
                  <AddIcon />
                  <h3>Add Picture</h3>
                </div>
              </label>
              {myImages?.length ? (
                <>
                  {myImages?.map((item, i) => {
                    return (
                      <li key={i}>
                        {/* <div className="uploaded"> */}
                        <ClearIcon
                          className="cross"
                          onClick={(e) => handleCrossClick(e, item)}
                        />
                        <figure>
                          <img src={item?.url} alt="upload" />
                        </figure>
                        {/* </div> */}
                      </li>
                    );
                  })}
                </>
              ) : null}
            </ul>

            {userData?.boosterBought || prodId ? null : (
              <div className="booster">
                <div className="boosterdiv">
                  <h3>Want to Buy Booster</h3>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={booseterCheck}
                    name="remember"
                    onChange={() => setBoosterCheck(!booseterCheck)}
                    // label="Want to Buy Booster"
                  />
                </div>
                {booseterCheck ? (
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedValue}
                      onChange={handleSortingOptions}
                    >
                      {booster &&
                        booster?.map((option: boosterPlans) => (
                          <FormControlLabel
                            className="rado_label"
                            key={option._id}
                            value={option._id}
                            control={<Radio />}
                            label={
                              <div>
                                <span>For {option?.validity}</span>
                                <span style={{ color: Colors.secondary }}>
                                  {" "}
                                  AED {option?.price}
                                </span>
                              </div>
                            }
                            labelPlacement="start" // Set labelPlacement to 'start' to display label before the radio button
                          />
                        ))}
                    </RadioGroup>
                  </div>
                ) : null}
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <FormControl fullWidth>
                <Select
                  className="select_add"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subCat}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleSubCategoryChange}
                >
                  <MenuItem value="" disabled>
                    Select Sub Category
                  </MenuItem>
                  {subCategories?.length
                    ? subCategories?.map((item: Subcategories) => (
                        <MenuItem key={item?._id} value={item?._id}>
                          {item?.name || ""}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {error && !subCat ? (
                  <h6 className="err_msg">This field is required</h6>
                ) : (
                  ""
                )}
              </FormControl>
              {/* brand */}
              {selectedSubcategory &&
              selectedSubcategory?.dynamicFields?.length ? (
                <FormControl fullWidth>
                  <Select
                    className="select_add"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={brand}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleBrandChange}
                  >
                    <MenuItem value="" disabled>
                      Select Brand
                    </MenuItem>
                    {selectedSubcategory?.brand?.length
                      ? selectedSubcategory?.brand?.map(
                          (item: string, i: number) => (
                            <MenuItem key={i} value={item}>
                              {item || ""}
                            </MenuItem>
                          )
                        )
                      : null}
                  </Select>
                  {error && !brand ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </FormControl>
              ) : null}
              {/* Dynamic Fields */}
              {selectedSubcategory?.dynamicFields?.length
                ? selectedSubcategory?.dynamicFields
                    .slice(1)
                    .map((field, index) => {
                      const fieldName = Object.keys(field)[0];
                      const fieldType = Object.values(field)[0];
                      console.log(fieldType, "fieldName: ", fieldName);

                      return (
                        <div key={index}>
                          {/* <label htmlFor={fieldName}>{fieldName}</label> */}
                          <InputField
                            key={index}
                            placeholder={fieldName}
                            name={fieldName}
                            id={fieldName}
                            value={formik.values[fieldName]}
                            onChange={(val) => {
                              if (
                                val.target.value === " " ||
                                val.target.value === "."
                              ) {
                              } else if (
                                fieldType === "string"
                                  ? isString(val.target.value)
                                  : isNumber(val.target.value)
                              ) {
                                formik.handleChange(val);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            // Add validation schema for dynamic fields if needed
                            helperText={
                              formik.touched[fieldName] &&
                              formik.errors[fieldName] && (
                                <div>{formik.errors[fieldName]}</div>
                              )
                            }
                          />
                        </div>
                      );
                    })
                : null}
              <InputField
                placeholder="Product name"
                name="productName"
                id="productName"
                inputProps={{ maxLength: 40 }}
                value={formik.values.productName}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.productName && formik.errors.productName
                }
              />

              <InputField
                placeholder="Price"
                name="price"
                id="price"
                value={
                  formik.values.price !== null
                    ? formatNumberWithCommas(formik.values.price)
                    : ""
                }
                inputProps={{ maxLength: 10 }}
                onChange={handlePriceChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.price && formik.errors.price}
              />

              <InputField
                placeholder="Location"
                name="location"
                id="location"
                value={formik.values.location}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.location && formik.errors.location}
              />

              <TextField
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder="Description"
                multiline
                rows={4}
                fullWidth
                name="description"
                id="description"
                value={formik.values.description}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <div className="MakeOff">
                <h3>Make an Offer</h3>
                <Switch
                  {...label}
                  checked={makeOffer}
                  size="small"
                  onChange={() => setMakeOffer(!makeOffer)}
                />
              </div>
              <div className="btn">
                {!prodId ? (
                  <div
                    className="add_prodct_dv"
                    onClick={() => {
                      setError(true);
                      selectedValue ? setOpen(true) : handleAddProduct();
                    }}
                  >
                    Publish Post
                  </div>
                ) : (
                  <Button value="Update Post" onClick={() => setError(true)} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>ADD NEW CARD</h2>
            <p>Enter your card details.</p>
            <form onSubmit={paymentFormik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <Typography variant="h6" className="custom_label2">
                    Card holder name
                  </Typography> */}
                  <TextField
                    fullWidth
                    value={paymentFormik.values.cardHolderName}
                    id="standard-basic"
                    placeholder="Card holder name"
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardHolderName"
                    inputProps={{ maxLength: 20 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardHolderName &&
                      paymentFormik.errors.cardHolderName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography variant="h6" className="custom_label2">
                    Card Number
                  </Typography> */}
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder="Card Number"
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <figure>
                            <img src="/static/images/visa.png" alt="" />
                          </figure>
                        </InputAdornment>
                      ),
                    }}
                    name="cardNumber"
                    inputProps={{ maxLength: 16 }}
                    value={paymentFormik.values.cardNumber}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardNumber &&
                      paymentFormik.errors.cardNumber
                    }
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <Grid item xs={5}>
                    {/* <Typography variant="h6" className="custom_label2">
                      Expiry Date
                    </Typography> */}
                    <TextField
                      className="paymnt_modl_txtfld"
                      variant="standard"
                      placeholder="MM/YYYY"
                      inputProps={{ maxLength: 7 }}
                      value={paymentFormik.values.expiryDate}
                      name="expiryDate"
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .replace(/\D/g, "") // Remove non-numeric characters
                          .replace(/(\d{2})(\d{0,4})/, "$1/$2"); // Format as MM/YYYY

                        paymentFormik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: formattedValue,
                          },
                        });
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Backspace") {
                          // Allow backspace for the month part
                          paymentFormik.handleChange({
                            target: {
                              name: "expiryDate",
                              value: paymentFormik.values.expiryDate.slice(
                                0,
                                -1
                              ),
                            },
                          });
                        }
                      }}
                      onBlur={paymentFormik.handleBlur}
                      helperText={
                        paymentFormik.touched.expiryDate &&
                        paymentFormik.errors.expiryDate
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {/* <Typography variant="h6" className="custom_label2">
                      CVV
                    </Typography> */}
                    <TextField
                      fullWidth
                      id="standard-basic"
                      placeholder="CVV"
                      variant="standard"
                      className="paymnt_modl_txtfld"
                      name="cvv"
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <figure className="cvv_img">
                              <img src="/static/images/cvv.svg" alt="" />
                            </figure>
                          </InputAdornment>
                        ),
                      }}
                      value={paymentFormik.values.cvv}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          paymentFormik.handleChange(val);
                        }
                      }}
                      onBlur={paymentFormik.handleBlur}
                      helperText={
                        paymentFormik.touched.cvv && paymentFormik.errors.cvv
                      }
                    />
                  </Grid>
                </Box>
                <Grid item xs={12} className="sv_crd">
                  <FormControlLabel
                    control={<Checkbox />}
                    // checked={formik.values.remember}
                    name="remember"
                    // onChange={formik.handleChange}
                    label="Save this card for future payments?"
                  />
                </Grid>
                <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                  <Button value="Save" />
                </Box>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddProduct;
