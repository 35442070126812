import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import { AddressRes } from "../types/General";

export interface General {
  cms: CMS | null;
  isAuthModalVisible: boolean;
  ModalType: string;
  email: string;
  phoneNo: string;
  countryCode: string;
  address: AddressRes[];
  addressId: string;
  addressName: string;
}
export interface CMS {
  countryCode: string;
  email: string;
  message: string;
  phone: string;
  privacyPolicy: string;
  about_us: string;
  _id: string;
}

const initialState: General = {
  cms: null,
  address: [],
  addressId: "",
  addressName: "",
  isAuthModalVisible: false,
  ModalType: "",
  email: "",
  phoneNo: "",
  countryCode: "",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<Pick<General, "cms">>) => {
      state.cms = action.payload.cms;
    },

    toggleAuthModal: (
      state,
      action: PayloadAction<Pick<General, "isAuthModalVisible" | "ModalType">>
    ) => {
      state.isAuthModalVisible = action.payload.isAuthModalVisible;
      state.ModalType = action.payload.ModalType;
    },
    verificationData: (
      state,
      action: PayloadAction<Pick<General, "email" | "phoneNo" | "countryCode">>
    ) => {
      state.email = action.payload.email;
      state.phoneNo = action.payload.phoneNo;
      state.countryCode = action.payload.countryCode;
    },
    addressData: (state, action: PayloadAction<Pick<General, "address">>) => {
      state.address = action.payload.address;
    },
  },
});
export const { resetCms, toggleAuthModal, verificationData, addressData } =
  generalSlice.actions;
export const getCms = (state: RootState) => state.general.cms;
export const isAuthModalVisible = (state: RootState) =>
  state.general.isAuthModalVisible;
export const ModalType = (state: RootState) => state.general.ModalType;
export const email = (state: RootState) => state.general.email;
export const phoneNo = (state: RootState) => state.general.phoneNo;
export const country = (state: RootState) => state.general.countryCode;
export const addressId = (state: RootState) => state.general.addressId;
export const addressName = (state: RootState) => state.general.addressName;
export const address = (state: RootState) => state.general.address;

export default generalSlice.reducer;
