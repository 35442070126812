import React, { Dispatch, SetStateAction } from "react";
import { Box, Checkbox, FormControlLabel, Modal } from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import "../auth/Auth.scss";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { usePostResetPasswordMutation } from "../../service/Auth";
import {
  STORAGE_KEYS,
  errorToast,
  removeFromStorage,
  successToast,
} from "../../helpers";
import { getTempToken, temporaryToken } from "../../reducers/authSlice";

type Props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const ResetPassword = ({ closeModal, setPhone }: Props) => {
  const dispatch = useAppDispatch();
  const [postResetPassword, { isLoading }] = usePostResetPasswordMutation();
  const tempToken = useAppSelector(getTempToken);
  console.log(tempToken, "tempToken");

  const handleSubmit = () => {
    closeModal();
    dispatch(toggleAuthModal({ isAuthModalVisible: false, ModalType: "" }));
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required!")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number."
        ),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("This field is required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const data = {
        password: formik.values.password,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postResetPassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          removeFromStorage(STORAGE_KEYS.tempToken);

          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );

          setPhone(false);
          dispatch(
            toggleAuthModal({
              isAuthModalVisible: true,
              ModalType: "login",
            })
          );
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross dbl">
          <KeyboardBackspaceIcon
            onClick={() => {
              closeModal();
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "forgot",
                })
              );
            }}
          />
          <CloseIcon onClick={() => closeModal()} />
        </div>
        <h2>Reset Password</h2>
        <div style={{ marginBottom: 20 }} />
        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder="New Password"
            password
            name="password"
            id="password"
            value={formik.values.password}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
          />
          <InputField
            placeholder="Confirm Password"
            password
            name="confirmPassword"
            id="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />

          <Button
            value="Next"
            //  onClick={handleSubmit}
          />
        </form>
        <div className="blacK" />
      </div>
    </div>
  );
};

export default ResetPassword;
