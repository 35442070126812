import { toast } from "react-toastify";

export const successToast = (message: string) => {
  toast.dismiss();
  toast.success(message);
};

export const showWarning = (message: string) => {
  toast.dismiss();
  toast.warning(message || "Something went wrong");
};

export const errorToast = (message: string) => {
  toast.dismiss();
  toast.error(message || "Something went wrong");
};
