import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { ProductCard } from "../../components";
import cardData from "../../../src/data/ProductData.json";
import { useGetAllfavPropertyMutation } from "../../service/Product";
import { Loader, errorToast } from "../../helpers";
import { RecommendedProductsData } from "../../types/General";

const Wishlist = () => {
  const [favProperty, { isLoading }] = useGetAllfavPropertyMutation();
  const [wishlistData, setWishlistData] = useState<RecommendedProductsData[]>(
    []
  );
  const [wishlistCount, setWishlistCount] = useState<number | undefined>();

  const getFavProperty = async () => {
    try {
      const response = await favProperty({
        type: "1",
      }).unwrap();
      if (response?.statusCode === 200) {
        setWishlistData(response?.data?.wishList || []);
        setWishlistCount(response?.data?.count);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = wishlistData
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [wishlistData]);

  useEffect(() => {
    getFavProperty();
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls">
        <div className="prodcts_sc container">
          <div className="crds_sec">
            {favItems?.length ? (
              favItems?.map((item) => {
                return (
                  <ProductCard
                    wishlist={item?.isFav}
                    key={item?._id}
                    product_id={item?._id}
                    product_image={item?.images[0]}
                    product_feature={item?.category?.name}
                    product_name={item?.name}
                    product_price={item?.price}
                    location={item?.address}
                    booster_applied={item?.boosterApplied}
                    setFavItems={setFavItems}
                    favItems={favItems}
                    item={item}
                  />
                );
              })
            ) : (
              <div className="noData">
                <figure>
                  <img src="/static/images/empty.png" alt="" />
                </figure>
                <h3>No Product Found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wishlist;
