import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout";
import { Button } from "@mui/material";
import "../profile/profile.scss";
import { ChatDetail } from "../../features";
import {
  useLazyGetChatListQuery,
  usePostClearChatMutation,
} from "../../service/Product";
import { ChatHistoryResponse, ChatResponse } from "../../types/General";
import io from "socket.io-client";

import { useAppSelector } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { getToken } from "../../reducers/authSlice";
import moment from "moment";
import { Loader, successToast } from "../../helpers";
import { useLocation } from "react-router-dom";

let rmid = "";

const Chat = () => {
  const location = useLocation();
  const { state } = location;
  const socketRef = useRef<SocketIOClient.Socket>();
  const token = useAppSelector(getToken);
  const userData = useAuth();
  const [isConnect, setIsConnect] = useState(false);
  const [tab, setTab] = useState(0);
  const [text, setText] = useState("");
  const [chats, setChats] = useState<ChatResponse[]>([]);
  const [chatHistory, setChatHistory] = useState<ChatHistoryResponse[]>([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedReceiverId, setSelectedReceiverId] = useState("");
  const [selectedReceiverName, setSelectedReceiverName] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("");
  const [selectedReceiverImage, setSelectedReceiverImage] = useState("");
  const [chatDeleted, setChatDeleted] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState("");

  const [getChatList, { isLoading }] = useLazyGetChatListQuery();
  const [clearChat] = usePostClearChatMutation();
  console.log(rmid, "rmid");
  console.log(selectedRoomId, "selectedRoomId");
  console.log(chatDeleted, "chatDeleted");

  const deleteChat = async () => {
    try {
      const response = await clearChat({
        roomId: selectedRoomId || "",
      }).unwrap();

      if (response?.statusCode === 200) {
        successToast("Chat deleted successfully");
        try {
          const response = await getChatList({
            roomId: selectedRoomId,
            filter: tab,
          }).unwrap();

          if (response?.statusCode === 200) {
            setChatHistory(response?.data?.chat || []);
            scrollToBottom();
          }
        } catch (error: any) {
          // setLoading(false);
        }
        setChatDeleted(true);
        setSelectedProductId("");
        setSelectedReceiverId("");
        setSelectedRoomId("");
      }
    } catch (error: any) {
      // setLoading(false);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const ele = document.getElementById("scrollTo");
      ele?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 100);
  };

  const getChatHistory = async () => {
    try {
      const response = await getChatList({
        roomId: "",
        filter: tab,
      }).unwrap();

      if (response?.statusCode === 200) {
        setChats(response?.data?.chat || []);
        if (state && (state.type === "notification" || state.type === "chat")) {
          try {
            const response = await getChatList({
              roomId: state?.roomId ? state?.roomId : "",
              filter: tab,
            }).unwrap();

            if (response?.statusCode === 200) {
              setChatHistory(response?.data?.chat || []);
              scrollToBottom();
            }
          } catch (error: any) {
            // setLoading(false);
          }
        }
      }
    } catch (error: any) {
      // setLoading(false);
    }
  };
  useEffect(() => {
    getChatHistory();
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, chatDeleted]);
  const handleChatClick = async (item: ChatResponse) => {
    setChatDeleted(false);
    setSelectedProductId(item?.productId?._id);
    setSelectedReceiverId(item?.userId?._id);
    setSelectedRoomId(item?.roomId);
    rmid = item?.roomId;
    setSelectedReceiverName(item?.userId?.fullName);
    setSelectedReceiverImage(item?.userId?.image);
    setSelectedProductName(item?.productId?.name);

    try {
      const response = await getChatList({
        roomId: item?.roomId ? item?.roomId : "",
        filter: tab,
      }).unwrap();

      if (response?.statusCode === 200) {
        setChatHistory(response?.data?.chat || []);
        scrollToBottom();
      }
    } catch (error: any) {
      // setLoading(false);
    }
  };
  const joinRoom = () => {
    const data = {
      productId: selectedProductId,
      senderId: userData?._id,
      receiverId: selectedReceiverId,
    };

    console.log(data, "datadatadata>>>");

    socketRef.current?.emit("connectToChat", data);
    socketRef.current?.on("connectToChatOk", async (message: any) => {
      console.log(message, "===room join====");
    });
  };

  const leaveRoom = async () => {
    const data = {
      roomId: rmid,
    };

    socketRef.current?.emit("disConnectToChat", data);
    socketRef.current?.on("disConnectToChatOk", async (message: any) => {
      console.log(message, "===room left====");
    });
  };

  const sendMessage = async () => {
    const body = {
      senderId: userData?._id,
      receiverId: selectedReceiverId,
      productId: selectedProductId,
      type: "TEXT",
      message: text,
    };

    const data = {
      // _id: selectedChatId,
      type: "TEXT",
      senderId: userData?._id,
      text: text,
      productId: selectedProductId,
      createdAt: new Date().toISOString(),
      product: selectedProductName,
      roomId: rmid,
    } as ChatHistoryResponse;

    if (socketRef?.current) {
      socketRef?.current.emit("sendMessage", body);
      if (body) {
        setChatHistory([...chatHistory, data]);
        setText("");
        scrollToBottom();
      }
    }
  };

  const recieveChat = async () => {
    if (socketRef.current) {
      socketRef?.current.on("receiveMessage", async (data: any) => {
        console.log("rood-----------------------", data);
        if (data?.senderId === userData?._id) {
          console.log(" null case  send message==================");
        } else {
          const body = {
            _id: data?._id,
            type: "TEXT",
            senderId: data?.senderId || "",
            text: data?.text || "",
            productId: data?.productId || "",
            createdAt: data?.createdAt,
            offer: data?.offer,
            product: data?.product || "",
            roomId: data?.roomId || "",
            amount: data?.amount || 0,
          } as ChatHistoryResponse;

          setChatHistory((history) => [...history, body]);

          setText("");
          scrollToBottom();
        }
      });
    }
  };

  useEffect(() => {
    console.log(isConnect, "jhgfhdsgfhdsg");

    if (isConnect) {
      joinRoom();
      recieveChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnect]);

  useEffect(() => {
    socketRef.current = io(
      `https://apidwmdev.appgrowthcompany.com?token=${token}`
    );

    const socket = socketRef.current;

    // Listens for incoming messages
    if (socket) {
      socketRef.current.on("connect", () => {
        console.log("Chat socket connection1", socketRef.current);
        setIsConnect(true);
      });
    }

    return () => {
      console.log("disconnect --");
      setIsConnect(false);
      leaveRoom();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomId]);

  useEffect(() => {
    if (state && (state.type === "notification" || state.type === "chat")) {
      rmid = state.roomId;
      setSelectedRoomId(state.roomId);
      setSelectedReceiverId(state.senderId);
      setSelectedReceiverImage(state.senderImage);
      setSelectedReceiverName(state.senderName);
      setSelectedProductName(state.productName);
      setSelectedProductId(state.productId);
    } else {
      rmid = "";
      setSelectedRoomId("");
      setSelectedReceiverId("");
      setSelectedReceiverImage("");
      setSelectedReceiverName("");
      setSelectedProductName("");
    }
  }, []);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <div className="wrapCls" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="pMain cht_revrs" style={{ alignItems: "normal" }}>
            <div className="pLeft" style={{ padding: 10 }}>
              <div className="tabs">
                <h3
                  className={tab === 0 ? "active" : ""}
                  onClick={() => setTab(0)}
                >
                  All
                </h3>
                <h3
                  className={tab === 1 ? "active" : ""}
                  onClick={() => setTab(1)}
                >
                  Buying
                </h3>
                <h3
                  className={tab === 2 ? "active" : ""}
                  onClick={() => setTab(2)}
                >
                  Selling
                </h3>
              </div>
              <ul className="chat">
                {chats?.length ? (
                  chats?.map((item) => (
                    <li
                      key={item?._id}
                      onClickCapture={() => {
                        leaveRoom();
                        handleChatClick(item);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <figure>
                          <img
                            src={
                              item?.userId?.image ||
                              "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                        </figure>
                        <h5>
                          {moment(item?.lastMessage?.updatedAt).format(
                            "hh:mm A"
                          )}
                        </h5>
                        <div className="contnt">
                          <h4
                            style={{ paddingBottom: "4px", color: "#820000" }}
                          >
                            {item?.productId?.name || ""}
                          </h4>
                          <h3>{item?.userId?.fullName || ""}</h3>
                          <p>{item?.lastMessage?.text || ""}</p>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="no_Chat">No Chats Found</div>
                )}
              </ul>
            </div>
            <div className="pRyt" style={{ padding: 30 }}>
              {
                // selectedProductId && selectedReceiverId &&
                rmid && !chatDeleted ? (
                  <ChatDetail
                    chatHistory={chatHistory}
                    setText={setText}
                    text={text}
                    sendMessage={sendMessage}
                    selectedProductName={selectedProductName}
                    selectedReceiverName={selectedReceiverName}
                    selectedReceiverImage={selectedReceiverImage}
                    selectedReceiverId={selectedReceiverId}
                    deleteChat={deleteChat}
                    setChatHistory={setChatHistory}
                    tab={tab}
                  />
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Go Ahead and start conversation
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chat;
