import React, { useEffect, useState } from "react";
import { ProductCard } from "../../components";
import { RecentProduct, RecommendedProductsData } from "../../types/General";
import cardData from "../../data/ProductData.json";
import { useNavigate } from "react-router-dom";

type props = {
  recentProductsData: RecentProduct[];
};
const PopularProducts = ({ recentProductsData }: props) => {
  const navigate = useNavigate();
  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = recentProductsData
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [recentProductsData]);

  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>Recent Products</h2>
        {recentProductsData?.length > 5 ? (
          <p onClick={() => navigate(`/all-products/${"recent"}`)}>See All</p>
        ) : null}
      </div>

      <div className="crds_sec">
        {recentProductsData?.length ? (
          recentProductsData?.slice(0, 5)?.map((item) => {
            return (
              <ProductCard
                key={item?._id}
                product_id={item?._id}
                product_image={item?.images[0]}
                product_feature={item?.category?.name}
                product_name={item?.name}
                product_price={item?.price}
                location={item?.address}
                booster_applied={item?.boosterApplied}
                setFavItems={setFavItems}
                favItems={favItems}
                item={item}
              />
            );
          })
        ) : (
          <div className="noData">
            <figure>
              <img src="/static/images/empty.png" alt="" />
            </figure>
            <h3>No Product Found</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default PopularProducts;
