import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, InputField } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { UploadMedia } from "../../utils/uploadMedia";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { usePostSetProfileMutation } from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { setCredentials } from "../../reducers/authSlice";
import { isString } from "../../utils/validations";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
};

const ProfileSetup = ({ closeModal, setPhone }: props) => {
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [phoneCode, setPhoneCode] = useState(user?.countryCode || "+971");
  const [image, setImage] = useState("");
  const [postSetProfile, { isLoading }] = usePostSetProfileMutation();

  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };
  console.log(user, "@@user");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: user?.fullName || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      fullName: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(40, "Maximum 40 character are allowed"),

      phone: Yup.string()
        .required("This field is required")
        .min(8, "Please enter correct phone number")
        .max(14)
        .label("Phone number"),
      address: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 3 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        fullName: formik.values.fullName,
        address: formik.values.address,
        // city: "",
        // state: "",
        // country:  "",
        // pinCode:  "",
        // latitude:"",
        // longitude: `${geometry?.longitude || 12}`,
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        image: image,
        isProfileComplete: true,
      };
      console.log(data, "profiledata");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postSetProfile(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          dispatch(
            toggleAuthModal({ isAuthModalVisible: false, ModalType: "" })
          );
          const token = getFromStorage(STORAGE_KEYS.token);
          successToast("Congratulations! Your profile setup is complete");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    setImage(user?.image || "");
  }, []);

  return (
    <div>
      <div className="AuthWrap">
        <div className="cross ">
          {/* <KeyboardBackspaceIcon /> */}

          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>Profile Setup</h2>
        <div className="image">
          <label htmlFor="icon-button-file">
            <Input
              sx={{ display: "none" }}
              id="icon-button-file"
              type="file"
              inputProps={{
                accept: "image/png,image/jpeg",
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files && event.target.files[0]) {
                  setImage(URL.createObjectURL(event.target.files[0]));
                  handleImageUpload(event.target.files[0]);
                }
              }}
            />

            <figure>
              {!image ? (
                <img src="/static/images/empty_user.png" alt="" />
              ) : (
                <img src={image} alt="" />
              )}
            </figure>
            <AddIcon />
          </label>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            placeholder="Full Name"
            name="fullName"
            id="fullName"
            value={formik.values.fullName}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.fullName && formik.errors.fullName}
          />
          <InputField
            placeholder="Email Id"
            disabled={user?.isEmailVerify}
            name="email"
            id="email"
            value={formik.values.email}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div className="form_control">
            <PhoneInput
              country={"in"}
              disabled={user?.isPhoneVerify}
              value={phoneCode + formik.values.phone}
              onChange={(phone: any, country: any) =>
                handleChangePhone(phone, country)
              }
              onBlur={formik.handleBlur}
              placeholder="0 (000) 000-000"
              enableSearch={false}
              inputStyle={{ width: "100%", backgroundColor: "#EBEBEB" }}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <h6 className="err_msg">
                {formik.touched.phone && formik.errors.phone}
              </h6>
            ) : (
              ""
            )}
            <div style={{ marginBottom: 10 }} />
          </div>
          <InputField
            placeholder="Address"
            name="address"
            id="address"
            value={formik.values.address}
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            helperText={formik.touched.address && formik.errors.address}
          />

          <Button
            value="Submit"
            // onClick={handleContinue}
          />
        </form>
      </div>
      <div style={{ marginBottom: 20 }} />
    </div>
  );
};

export default ProfileSetup;
