import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import Login from "../../features/auth/Login";
import {
  Banners,
  Categories,
  DownloadApp,
  FeaturedProducts,
  PopularProducts,
  RecommendedProducts,
} from "../../features";
import "./Home.scss";
import {
  useGetRecentProductsMutation,
  useGuestLoginMutation,
  useLazyGetDashboardQuery,
} from "../../service/Home";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
} from "../../helpers";
import {
  CommonBody,
  FeaturedProductsData,
  ProductBannerData,
  RecentProduct,
  RecommendedProductsData,
} from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  getTempToken,
  setCredentials,
  temporaryToken,
} from "../../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";

const HomePage = () => {
  const user = useAuth();
  const dispatch = useAppDispatch();

  // const [guestLoginMutation] = useGuestLoginMutation();

  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [productBannerData, setProductBannerData] = useState<
    ProductBannerData[]
  >([]);
  const [featuredProducts, setFeaturedProducts] = useState<
    FeaturedProductsData[]
  >([]);
  const [recentProductsData, setRecentProductsData] = useState<RecentProduct[]>(
    []
  );
  const [recommendedProducts, setRecommendedProducts] = useState<
    RecommendedProductsData[]
  >([]);
  const [getDashboard, { isLoading }] = useLazyGetDashboardQuery();
  const [recentProducts, GetRecentProductsData] =
    useGetRecentProductsMutation();

  const fetchData = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const body = {
      deviceType: "WEB",
      deviceToken: fcmtoken || "1234",
    };
    try {
      const response = await getDashboard(body).unwrap();
      if (response?.statusCode === 200) {
        setProductBannerData(
          response?.data?.advertisement?.productBanner || []
        );
        setRecommendedProducts(response?.data?.recommendedProduct || []);
        setFeaturedProducts(response?.data?.featuredProducts || []);
      } else {
        setProductBannerData([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const getRecentProducts = async () => {
    const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
    const body = {
      deviceType: "WEB",
      deviceToken: fcmtoken || "1234",
    };
    try {
      const response = await recentProducts({}).unwrap();
      if (response?.statusCode === 200) {
        setRecentProductsData(response?.data?.products || []);
      } else {
        setRecentProductsData([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (token || tempToken) {
      fetchData();
      getRecentProducts();
    }
  }, [tempToken]);

  return (
    <Layout>
      <div className="wrapCls">
        <Loader isLoad={isLoading} />
        <Banners productBannerData={productBannerData} />
        <Categories />
        {featuredProducts.length === 0 &&
        recentProductsData.length === 0 &&
        recommendedProducts.length === 0 ? (
          <div
            className="noData"
            style={{ marginTop: productBannerData?.length ? 100 : 0 }}
          >
            <figure>
              <img src="/static/images/empty.png" alt="" />
            </figure>
            <h3>No Product Found</h3>
          </div>
        ) : (
          <>
            <PopularProducts recentProductsData={recentProductsData} />
            <FeaturedProducts featuredProducts={featuredProducts} />

            <RecommendedProducts recommendedProducts={recommendedProducts} />
          </>
        )}
      </div>
      <DownloadApp />
    </Layout>
  );
};

export default HomePage;
