import React, { useEffect, useState } from "react";
import { useLazyGetCategoriesQuery } from "../../service/Home";
import { Category } from "../../types/General";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const navigate = useNavigate();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [categories, setCategories] = useState<Category[]>([]);
  const [getCategories, GetCategoriesData] = useLazyGetCategoriesQuery();

  const getAllCategories = async () => {
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
    }
  }, [tempToken]);
  return (
    <section className="hm_categories container ">
      <ul>
        {categories?.length
          ? categories?.map((item) => {
              return (
                <li
                  key={item?._id}
                  onClick={() =>
                    navigate(`/products/${item?._id}`, { state: item?.name })
                  }
                >
                  <figure>
                    <img src={item?.image[0] || ""} alt="" />
                  </figure>
                  <h6>{item?.name}</h6>
                </li>
              );
            })
          : null}
      </ul>
    </section>
  );
};

export default Categories;
